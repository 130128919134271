import React, {useState} from "react";
import { Row, Col } from 'antd'
import HeaderComponent from '../../components/header'
import FooterComponent from '../../components/footer'
import {T1, T2, T3} from '../../assets/images/torus'
import {ArrowRight} from '../../assets/images'
import './style.css'
const TorusPage = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const toggleMenu = () => {
        console.log("menuVisible", menuVisible);
        setMenuVisible(!menuVisible);
    };
    return(
        <>
            <HeaderComponent flag={false} toggleMenu={toggleMenu} menuVisible={menuVisible} /> 
            <div className="main_head_torus">
                <Row>
                    <Col span={4} />
                    <Col span={11}>
                        <h1 className="fix_head_txt"> Torus <br/> Investment <span className="head_cay_txt"> LLC </span> </h1>
                        <span className="head_sub_txt"> 
                            Torus: A Diversified Investment Holding Company in Real Estate, Industrial, and Commercial Projects
                        </span>
                    </Col>
                </Row>
                <Row className="brd_row">
                    <Col span={4} />
                    <Col span={11}>
                        <span className="head_brd_txt"> 
                           Home / Torus Investment LLC
                        </span>
                    </Col>
                </Row>
            </div>
            <div>
                <Row className="center_div" gutter={[10,10]}>
                    <Col span={24}>
                        <h1 className="net_main_txt"> Torus is Powering Investments Across Industries </h1>
                        <p className="net_sub_txt" > operating across multiple regions, including Dubai, Abu Dhabi, Sharjah, Ras Al Khaimah, Lebanon, and potentially more, investing in properties and financial institutions.</p>
                        <p className="net_sub_txt">
                            Torus is established in Dubai and in British Virgin Islands (BVI), as an Investment holding Company. Torus invests directly and indirectly in Real Estate projects, Industrial and Commercial Projects. Torus has its own investment Board, which make policies and decisions on investment solutions. Torus management and consultancy unit study and establish value added Business in collaboration with national and international companies. Currently Torus Investment portfolio includes many properties in Dubai, Abu Dhabi, Sharjah, Ras Al Khaimah, Lebanon, Value Added Industrial Projects and investment in financial institutions, i.e. Stock Exchange etc.
                        </p>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Completed Projects </h1>
                        <span> 
                            <p>
                                <img src={ArrowRight}  alt='icon' className="icon_width"  />
                                BID2 DWF
                            </p>
                            <p>
                                <img src={ArrowRight}  alt='icon' className="icon_width"  />
                                RAK Villas
                            </p>
                            <ul>
                                <li> Khorfakkan Land </li>
                                <li> Ind. Area # 13, Sharjah </li>
                                <li> Garden Villa, DWF </li>
                                <li> Park View, Ajman </li>
                                <li> DFM & ADSM Securities </li>
                                <li> Asas Land, Sharjah </li>
                                <li> Buteen Building, Sharjah </li>
                            </ul>
                        </span>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <img src={T1} alt='icon' className="net_img" />
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <img src={T2} alt='icon' className="net_img"  />
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Ongoing Projects </h1>
                        <span> 
                            <p> 
                                <img src={ArrowRight}  alt='icon' className="icon_width" />
                                Sonali Tower Project
                            </p>
                            <p>
                                <img src={ArrowRight}  alt='icon' className="icon_width" />
                                Al Jurf 13, Ajman
                            </p>
                            <ul>
                                <li> Down Town, Jebel Ali </li>
                                <li> Town House, DWF </li>
                                <li> Sigma Tower, Abu Dhabi </li>
                                <li> Sky Garden, Abu Dhabi </li>
                                <li> Labor Camp, Jebel Ali </li>
                                <li> Dana Island, Plots, RAK </li>
                                <li> Gate Way City, RAK </li>
                                <li> Sky Courts Tower </li>
                                <li> Al Ghanim Business Centre, SHJ </li>
                                <li> XAD Technologies LLC (FTTH) </li>
                                <li> Lebanon Project </li>
                                <li> Saudi Arabia Investment </li>
                                <li> Innomix Block Manufacturing </li>
                            </ul>
                        </span>
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Products & Specialties </h1>
                        <span> 
                            <p>
                                <img src={ArrowRight} alt="iccc" className="icon_width" />
                                Investment in Real Estate Projects
                            </p>
                            <p>
                                <img src={ArrowRight} alt="iccc" className="icon_width" />
                                Investment in Industrial and Commercial Projects
                            </p>
                            <ul>
                                <li> Investment in Commercial Projects </li>
                            </ul>  
                        </span>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <img src={T3} alt='icon' className="net_img"  />
                    </Col> 
                </Row>
            </div>
            <FooterComponent normal={true}  />
        </>
    )
}

export default TorusPage