// NokiaIcon
import React, { useState } from "react";
import { Row, Col } from "antd";
import HeaderComponent from "../../components/header";
import FooterComponent from "../../components/footer";
import {
    Row1_1,
    Row1_2,
    Row1_3,
    Row1_4,
    Row1_5,
    Row1_6,
    Row2_1,
    Row2_2,
    Row2_3,
    Row2_4,
    Row2_5,
    Row2_6,
    Row3_1,
    Row3_2,
    Row3_3,
    Row3_4,
    Row3_5,
    Row3_6,
    Row4_1,
    Row4_2,
    Row4_3,
    Row4_4,
    Row4_5,
    Row4_6,
    Row5_1,
    Row5_2        
} from '../../assets/images/galary'
import "./style.css";
const GalleryPage = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const toggleMenu = () => {
    console.log("menuVisible", menuVisible);
    setMenuVisible(!menuVisible);
  };
  return (
    <>
      <HeaderComponent
        flag={false}
        toggleMenu={toggleMenu}
        menuVisible={menuVisible}
      />
      <div className="main_head_gallery">
        <Row>
          <Col span={4} />
          <Col span={11}>
            <h1 className="fix_head_txt">
            Gallery <p className="head_cay_txt"> Images </p>
            </h1>
            <span className="head_sub_txt">
                Watch Unforgettable Moments & essence of our journey: Celebratory snapshots showcasing our annual functions, employee recognition, and memorable milestones.
            </span>
          </Col>
        </Row>
        <Row className="brd_row">
          <Col span={4} />
          <Col span={11}>
            <span className="head_brd_txt">Home / Gallery Images  </span>
          </Col>
        </Row>
      </div>
      <div>
        <Row className="cent_div">
          <Col span={24}>
            {/*  */}
            <h1 className="top_clnt_txt"> Memorable Moments </h1>
            <span className="top_sub_txt">
            Annual Functions, Awards, and Employee Appreciation
            </span>
          </Col>
        </Row>
        <Row className="cent_div">
           <Col span={24}>
            <Row gutter={[10,10]}>
                <Col span={6}>
                    <img src={Row1_1} alt="g1" className="gal_img" />
                    <img src={Row1_2} alt="g1" className="gal_img" />
                </Col>
                <Col span={12}>
                    <img src={Row1_3} alt="g1" className="gal_img" />
                </Col>
                <Col span={6}>
                    <Row>
                        <Col span={12}> <img src={Row1_4} alt="g1" className="gal_img" /> </Col>
                        <Col span={12}> <img src={Row1_5} alt="g1" className="gal_img" /> </Col>
                    </Row>
                    <img src={Row1_6} alt="g1" className="gal_img" />
                </Col>
            </Row>
           </Col>
           <Col span={24}>
            <Row gutter={[10,10]}>
                <Col span={8}>
                    <Row>
                        <Col span={12}> <img src={Row2_1} alt="g1" className="gal_img" /> </Col>
                        <Col span={12}> <img src={Row2_2} alt="g1" className="gal_img" /> </Col>
                    </Row>
                    <img src={Row2_3} alt="g1" className="gal_img" />
                </Col>
                <Col span={12}>
                    <img src={Row2_4} alt="g1" className="gal_img" />
                </Col>
                <Col span={4}>
                    <img src={Row2_5} alt="g1" className="gal_img" />
                    <img src={Row2_6} alt="g1" className="gal_img" />
                </Col>
            </Row>
           </Col>
           <Col span={24}>
            <Row gutter={[10,10]}>
                <Col span={4}>
                    <img src={Row3_1} alt="g1" className="gal_img" /> 
                    <img src={Row3_2} alt="g1" className="gal_img" /> 
                </Col>
                <Col span={12}>
                    <img src={Row3_3} alt="g1" className="gal_img" />
                </Col>
                <Col span={8}>
                    <Row>
                        <Col span={12}> <img src={Row3_4} alt="g1" className="gal_img" /> </Col>
                        <Col span={12}> <img src={Row3_5} alt="g1" className="gal_img" /> </Col>
                    </Row>
                    <img src={Row3_6} alt="g1" className="gal_img" />
                </Col>
            </Row>
           </Col>
           <Col span={24}>
            <Row gutter={[10,10]}>
                <Col span={6}>
                    <img src={Row4_1} alt="g1" className="gal_img" /> 
                    <img src={Row4_2} alt="g1" className="gal_img" /> 
                </Col>
                <Col span={6}>
                    <img src={Row4_3} alt="g1" className="gal_img" />
                </Col>
                <Col span={12}>
                    <img src={Row4_4} alt="g1" className="gal_img" />
                    <Row>
                        <Col span={12}> <img src={Row4_5} alt="g1" className="gal_img" /> </Col>
                        <Col span={12}> <img src={Row4_6} alt="g1" className="gal_img" /> </Col>
                    </Row>
                </Col>
            </Row>
           </Col>
           <Col span={24}>
            <Row gutter={[10,10]}>
                <Col span={12}> <img src={Row5_1} alt="g1" className="gal_img" />  </Col>
                <Col span={12}> <img src={Row5_2} alt="g1" className="gal_img" />  </Col>
            </Row>
           </Col>
        </Row>
      </div>
      <FooterComponent normal={true} />
    </>
  );
};

export default GalleryPage;