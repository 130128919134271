import React, { useState } from "react";
import { Row, Col } from "antd";
import HeaderComponent from "../../components/header";
import FooterComponent from "../../components/footer";
import {
  //   EtisalatIcon,
  EricssonIcon,
  GreyTickIcon,
  WhiteArrowIcon,
} from "../../assets/images/our_projects";
import "./style.css";
const EricssonPage = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const toggleMenu = () => {
    console.log("menuVisible", menuVisible);
    setMenuVisible(!menuVisible);
  };
  return (
    <>
      <HeaderComponent
        flag={false}
        toggleMenu={toggleMenu}
        menuVisible={menuVisible}
      />
      <div className="main_head_eric">
        <Row>
          <Col span={4} />
          <Col span={11}>
            <h1 className="fix_head_txt">
              Project <p className="head_cay_txt"> Ericsson </p>
            </h1>
            <span className="head_sub_txt">
                Ericsson's project revolutionized telecom with 5G and sustainability, reshaping global connectivity through strategic partnerships.
            </span>
          </Col>
        </Row>
        <Row className="brd_row">
          <Col span={4} />
          <Col span={11}>
            <span className="head_brd_txt">Home /  Project Ericsson</span>
          </Col>
        </Row>
      </div>
      <div className="main_crr_div">
        <Row>
          <Col span={4} className="icon_div">
            <img src={EricssonIcon} alt="icon" />
          </Col>
          <Col span={20}>
            <h1 className="net_main_txt"> XAD Projects with Ericsson </h1>
            <p className="net_sub_txt">
                Here are some of our top projects with Ericsson
            </p>
          </Col>

         
        </Row>
      </div>
      <div className="cent_div">
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt">  Ericsson – Etisalat Swap Project 2016 </p>
          </Col>
          <Col span={2}>
            <img src={WhiteArrowIcon} alt="tcic" className="icons" />
          </Col>
        </Row>
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt">  Advanced Metering Infrastructure (AMI) </p>
          </Col>
          <Col span={2}>
            <img src={WhiteArrowIcon} alt="tcic" className="icons" />
          </Col>
        </Row>
        
      </div>
      <FooterComponent normal={true} />
    </>
  );
};

export default EricssonPage;