// NokiaIcon
import React, { useState } from "react";
import { Row, Col } from "antd";
import HeaderComponent from "../../components/header";
import FooterComponent from "../../components/footer";
import {OrganIcon} from '../../assets/images'
import {CompAni} from '../../assets/images/animations'
import LottieAnimation from "../../components/animation";
import "./style.css";
const OrganStrucPage = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const toggleMenu = () => {
    console.log("menuVisible", menuVisible);
    setMenuVisible(!menuVisible);
  };
  return (
    <>
      <HeaderComponent
        flag={false}
        toggleMenu={toggleMenu}
        menuVisible={menuVisible}
      />
      <div className="main_head_client">
        <Row>
          <Col span={4} />
          <Col span={11}>
            <h1 className="fix_head_txt">
            Company <p className="head_cay_txt"> Structure </p>
            </h1>
            <span className="head_sub_txt">
                Explore the interconnected framework delineating roles and relationships within our organization, fostering synergy and clarity in our operations."
            </span>
          </Col>
        </Row>
        <Row className="brd_row">
          <Col span={4} />
          <Col span={11}>
            <span className="head_brd_txt">Home / Company Structure</span>
          </Col>
        </Row>
      </div>
      <div className="head_3">
        <Row>
          <Col span={24} align="middle" className="text-center">
            <LottieAnimation animationData={CompAni} className="width_8" />
            <h1 className="top_clnt_txt"> Organizational Network </h1>
            <span className="top_sub_txt">
                Check our visual company connections, a brief diagram of our company structure
            </span>
          </Col>
        </Row>
        <Row>
            <Col span={24}> <img src={OrganIcon} alt="organ" /> </Col>
        </Row>
      </div>
      <FooterComponent normal={true} />
    </>
  );
};

export default OrganStrucPage;