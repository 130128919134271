// NokiaIcon
import React, { useState } from "react";
import { Row, Col } from "antd";
import HeaderComponent from "../../components/header";
import FooterComponent from "../../components/footer";
import {
  GreyTickIcon,
  HauwIcon,
  WhiteArrowIcon,
  Arrow,
} from "../../assets/images/our_projects";
import "./style.css";
const HuaweiPage = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const toggleMenu = () => {
    console.log("menuVisible", menuVisible);
    setMenuVisible(!menuVisible);
  };
  return (
    <>
      <HeaderComponent
        flag={false}
        toggleMenu={toggleMenu}
        menuVisible={menuVisible}
      />
      <div className="main_head_huaw">
        <Row>
          <Col span={4} />
          <Col span={11}>
            <h1 className="fix_head_txt">
              Project <p className="head_cay_txt"> Huawei </p>
            </h1>
            <span className="head_sub_txt">
                Huawei's project aimed to revolutionize telecommunications through cutting-edge technologies like 5G, emphasizing innovation and strategic collaborations for industry advancement.
            </span>
          </Col>
        </Row>
        <Row className="brd_row">
          <Col span={4} />
          <Col span={11}>
            <span className="head_brd_txt">Home / Project Huawei</span>
          </Col>
        </Row>
      </div>
      <div className="main_crr_div">
        <Row>
          <Col span={4} className="icon_div">
            <img src={HauwIcon} alt="icon" />
          </Col>
          <Col span={20}>
            <h1 className="net_main_txt"> XAD Projects with Huawei </h1>
            <p className="net_sub_txt">
                Here are some of our top projects with Huawei
            </p>
          </Col>
        </Row>
      </div>
      <div className="cent_div_n">
        <Row>
          <Col span={24}>
            <div>
              <img src={Arrow} alt="Nokia arrow" />
              <span className="arrow_txt">
                Installation & Upgrading of Microwave 1.8/ 1.2/ 0.6/ 0.3
              </span>
            </div>
            <div>
              <img src={Arrow} alt="Nokia arrow" />
              <span className="arrow_txt">
                BSS(Base Station System) / RAN(Radio Access Network) Rollout.
              </span>
            </div>
            <div>
              <img src={Arrow} alt="Nokia arrow" />
              <span className="arrow_txt">
                BSS / RAN Swap
              </span>
            </div>
            <div>
              <img src={Arrow} alt="Nokia arrow" />
              <span className="arrow_txt">
                BSS / RAN IBS(Indoor Building Solution).
              </span>
            </div>
            <div>
              <img src={Arrow} alt="Nokia arrow" />
              <span className="arrow_txt">
                BSS / RAN Expansion.
              </span>
            </div>
            <div>
              <img src={Arrow} alt="Nokia arrow" />
              <span className="arrow_txt">
                Transmission(PDH,SDH).
              </span>
            </div>
            <div>
              <img src={Arrow} alt="Nokia arrow" />
              <span className="arrow_txt">
                Core Implementation (MSC/BSC).
              </span>
            </div>
            <div>
              <img src={Arrow} alt="Nokia arrow" />
              <span className="arrow_txt">
                Telecom and installation of BTS & MW
              </span>
            </div>
          </Col>
        </Row>
        <br/ >
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt"> Huawei MBB </p>
          </Col>
          <Col span={2}>
            <img src={WhiteArrowIcon} alt="tcic" className="icons" />
          </Col>
        </Row>
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt"> Huawei TI </p>
          </Col>
          <Col span={2}>
            <img src={WhiteArrowIcon} alt="tcic" className="icons" />
          </Col>
        </Row>
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt">Huawei GPON</p>
          </Col>
          <Col span={2}>
            <img src={WhiteArrowIcon} alt="tcic" className="icons" />
          </Col>
        </Row>
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt"> Huawei PIM </p>
          </Col>
          <Col span={2}>
            <img src={WhiteArrowIcon} alt="tcic" className="icons" />
          </Col>
        </Row>
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt"> Huawei Expo </p>
          </Col>
          <Col span={2}>
            <img src={WhiteArrowIcon} alt="tcic" className="icons" />
          </Col>
        </Row>
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt"> Huawei OLT </p>
          </Col>
          <Col span={2}>
            <img src={WhiteArrowIcon} alt="tcic" className="icons" />
          </Col>
        </Row>
      </div>
      <FooterComponent normal={true} />
    </>
  );
};

export default HuaweiPage;