// NokiaIcon
import React, { useState } from "react";
import { Row, Col } from "antd";
import HeaderComponent from "../../components/header";
import FooterComponent from "../../components/footer";
import {
  GreyTickIcon,
  SolarIcon,
  WhiteArrowIcon,
  Arrow,
} from "../../assets/images/our_projects";
import "./style.css";
const SolarPage = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const toggleMenu = () => {
    console.log("menuVisible", menuVisible);
    setMenuVisible(!menuVisible);
  };
  return (
    <>
      <HeaderComponent
        flag={false}
        toggleMenu={toggleMenu}
        menuVisible={menuVisible}
      />
      <div className="main_head_solar">
        <Row>
          <Col span={4} />
          <Col span={11}>
            <h1 className="fix_head_txt">
            Solar <p className="head_cay_txt"> Projects </p>
            </h1>
            <span className="head_sub_txt">
                Solar projects deploy panels to generate clean energy, powering homes, businesses, and communities for a sustainable future while driving innovation for enhanced efficiency and wider applications.
            </span>
          </Col>
        </Row>
        <Row className="brd_row">
          <Col span={4} />
          <Col span={11}>
            <span className="head_brd_txt">Home / Solar Energy Projects</span>
          </Col>
        </Row>
      </div>
      <div className="main_crr_div">
        <Row>
          <Col span={4} className="icon_div">
            <img src={SolarIcon} alt="icon" />
          </Col>
          <Col span={20}>
            <h1 className="net_main_txt"> XAD Solar Energy Projects </h1>
            <p className="net_sub_txt">
                Here are some of our top Solar Energy Projects
            </p>
          </Col>
          
        </Row>
      </div>
      <div className="cent_div_n">
        <Row>
            <Col span={24}>
                <p className="net_sub_txt"> 
                    XAD Technologies started operating in the field of renewable energy. We are active in Survey, designing, Civil Work, installing, consulting and setting up solar systems (On grid, Off-grid, Hybrid). Xad plans and builds all sizes of renewable power plants for international investors in UAE and middle east region.
                    Xad provides best in class Photovoltaic panels with outstanding performance warranties. We provide all kind of solar panels, Batteries, solar charge controller and inverters.
                </p>
            </Col>
            <Col span={24}>
                <Row gutter={[10, 10]} className="gry_div">
                    <Col span={2}>
                        <img src={GreyTickIcon} alt="tcic" className="icons" />
                    </Col>
                    <Col span={20}>
                        <p className="grry_txt">DU Solar Projects</p>
                    </Col>
                    <Col span={2}>
                        <img src={WhiteArrowIcon} alt="tcic" className="icons" />
                    </Col>
                </Row>
                <Row gutter={[10, 10]} className="gry_div">
                    <Col span={2}>
                        <img src={GreyTickIcon} alt="tcic" className="icons" />
                    </Col>
                    <Col span={20}>
                        <p className="grry_txt">Huawei Solar Projects</p>
                    </Col>
                    <Col span={2}>
                        <img src={WhiteArrowIcon} alt="tcic" className="icons" />
                    </Col>
                </Row>
                <Row gutter={[10, 10]} className="gry_div">
                    <Col span={2}>
                        <img src={GreyTickIcon} alt="tcic" className="icons" />
                    </Col>
                    <Col span={20}>
                        <p className="grry_txt">SBA Solar Projects</p>
                    </Col>
                    <Col span={2}>
                        <img src={WhiteArrowIcon} alt="tcic" className="icons" />
                    </Col>
                </Row>
                <Row gutter={[10, 10]} className="gry_div">
                    <Col span={2}>
                        <img src={GreyTickIcon} alt="tcic" className="icons" />
                    </Col>
                    <Col span={20}>
                        <p className="grry_txt">SAIPEM Solar Project</p>
                    </Col>
                    <Col span={2}>
                        <img src={WhiteArrowIcon} alt="tcic" className="icons" />
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <h1 className="net_main_txt">  Our Exclusive Solar Energy Solutions </h1>
                <div>
                <img src={Arrow} alt="Nokia arrow" />
                <span className="arrow_txt">
                    Solar Installations
                </span>
                </div>
                <div>
                <img src={Arrow} alt="Nokia arrow" />
                <span className="arrow_txt">
                    Power Cabinets
                </span>
                </div>
                <div>
                <img src={Arrow} alt="Nokia arrow" />
                <span className="arrow_txt">
                    Substation Works
                </span>
                </div>
                <div>
                <img src={Arrow} alt="Nokia arrow" />
                <span className="arrow_txt">
                    Rollout and Maintenance
                </span>
                </div>
            </Col>
            <br />
            <Col span={24}>
                <h1 className="net_main_txt"> Service Partners In UAE: </h1>
                <span className="arrow_txt"> Etisalat, Du, Huawei, Nokia, Ericsson, Dewa, Adwea, Alstom, Etihad Rail Partner </span>
            </Col>
        </Row>
      </div>
      <FooterComponent normal={true} />
    </>
  );
};

export default SolarPage;