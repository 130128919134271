import React, {useState} from "react";
import { Row, Col, Form, Input, Button, Switch, Select  } from 'antd'
import HeaderComponent from '../../components/header'
import ProfImg from '../../assets/images/prof.png'
import FooterComponent from "../../components/footer";
import './style.css'
const {TextArea} = Input
const {Option} = Select
const CareerPage = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const [form] = Form.useForm()
    const toggleMenu = () => {
        console.log("menuVisible", menuVisible);
        setMenuVisible(!menuVisible);
    };
    const submitForm = (values) => {
        console.log('values ', values);
    }
    return(
        <>
            <HeaderComponent flag={false} toggleMenu={toggleMenu} menuVisible={menuVisible}  /> 
            <div className="main_head_career">
                <Row>
                    <Col span={4} />
                    <Col span={11}>
                        <h1 className="fix_head_txt"> Professional <span className="head_cay_txt"> Career </span> </h1>
                        <span className="head_sub_txt"> 
                        Unlock Your Professional Potential: Seamlessly apply for job opportunities by submitting your information, portfolio, and CV. Join us on the path to a rewarding career journey.
                        </span>
                    </Col>
                </Row>
                <Row className="brd_row">
                    <Col span={4} />
                    <Col span={11}>
                        <span className="head_brd_txt"> 
                           Home / Career
                        </span>
                    </Col>
                </Row>
            </div>
            <div className="grey_bg">
                <Row>
                    <Col span={24} className="main_crr_div">
                        <h1 className="net_main_txt"> Your Career Opportunity</h1>
                        <span className="net_sub_txt"> create a profile here</span>
                    </Col>
                </Row>
                <Row className="center_div" gutter={[10,10]}>
                    <Col span={8}>
                        <img src={ProfImg} alt="padfs" className="width_55" />
                    </Col>
                    <Col span={14}>
                        <Form form={form} onFinish={submitForm}>
                            <Row gutter={[10,10]}>
                                <Col span={12}>
                                    <Form.Item name="first_name">
                                        <Input placeholder="First Name" className="input" />
                                    </Form.Item>
                                    <Form.Item name="email">
                                        <Input placeholder="Email" className="input"  />
                                    </Form.Item>
                                    <Form.Item name="country">
                                        <Select placeholder="Country" className="input" >
                                            <Option value="pak"> Pakistan </Option>
                                            <Option value="uae"> UAE </Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="gender">
                                        <Select placeholder="Gender" className="input" >
                                            <Option value="male"> Male </Option>
                                            <Option value="female"> Female </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="last_name">
                                        <Input placeholder="Last Name" className="input"  />
                                    </Form.Item>
                                    <Form.Item name="mob_num">
                                        <Input placeholder="Mobile Number" className="input"  />
                                    </Form.Item>
                                    <Form.Item name="subject">
                                        <Input placeholder="subject" className="input"  />
                                    </Form.Item>
                                    <Form.Item name="proft_link">
                                        <Input placeholder="Portfolio link" className="input"  />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name="message">
                                        <TextArea placeholder="Message"  className="input" />
                                    </Form.Item>
                                    <Form.Item name="file">
                                        <Input type="file" className="input"  />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Switch  />
                                    <br />
                                    Email Notification
                                    <br />
                                    Notify me about my hiring
                                </Col>
                                <Col span={12}>
                                    <Form.Item>
                                        <Button  htmlType="submit" className="send_btn"> Send </Button>
                                    </Form.Item>
                                    
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </div>
            <FooterComponent normal={true}  />
        </>
    )
}

export default CareerPage