// NokiaIcon
import React, { useState } from "react";
import { Row, Col, Divider } from "antd";
import HeaderComponent from "../../components/header";
import FooterComponent from "../../components/footer";
import {LicinAni} from '../../assets/images/animations'
import LottieAnimation from "../../components/animation";
import "./style.css";
const LicensesPage = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const toggleMenu = () => {
    console.log("menuVisible", menuVisible);
    setMenuVisible(!menuVisible);
  };
  return (
    <>
      <HeaderComponent
        flag={false}
        toggleMenu={toggleMenu}
        menuVisible={menuVisible}
      />
      <div className="main_head_licenses">
        <Row>
          <Col span={4} />
          <Col span={11}>
            <h1 className="fix_head_txt">
            Commercial <p className="head_cay_txt"> Licenses </p>
            </h1>
            <span className="head_sub_txt">
                Watch our licenses in action, exemplifying our commitment to regulatory compliance and business operations.
            </span>
          </Col>
        </Row>
        <Row className="brd_row">
          <Col span={4} />
          <Col span={11}>
            <span className="head_brd_txt">Home / Commercial Licenses  </span>
          </Col>
        </Row>
      </div>
      <div>
        <Row className="cent_div">
          <Col span={24}>
            {/*  */}
            <h1 className="top_clnt_txt"> Check out our Licences </h1>
            <span className="top_sub_txt">
            See How We Do Business Legally and Flexibly with our Commercial Licenses
            </span>
          </Col>
        </Row>
        <Row className="cent_div">
            <Col span={24}> <h1> Abu Dhabi Commercial License </h1> </Col>
            <Col span={24}>
                <Row className="lin_card">
                    <Col span={2} className="lin_ani_div">
                        <LottieAnimation animationData={LicinAni} className="lin_ani" />
                    </Col>
                    <Col span={2}>
                        <Divider type="vertical" className="lin_divd" />
                    </Col>
                    <Col span={20}>
                        <h2 className="lin_main_txt"> XAD Technologies LLC</h2>
                        <p className="lin_sub_txt">
                            <a
                                href="https://www.xadtechnologies.com/wp-content/uploads/2021/06/XAD-AUH-TRADE-LICENSE.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Download PDF
                            </a>
                        </p>
                    </Col>
                </Row>
                <Row className="lin_card">
                    <Col span={2} className="lin_ani_div">
                        <LottieAnimation animationData={LicinAni} className="lin_ani" />
                    </Col>
                    <Col span={2}>
                        <Divider type="vertical" className="lin_divd" />
                    </Col>
                    <Col span={20}>
                        <h2 className="lin_main_txt"> XAD Communication Trade License</h2>
                        <p className="lin_sub_txt"> 
                        <a
                            href="https://www.xadtechnologies.com/wp-content/uploads/2021/06/ZAD-Communication-Trade-License-AUH.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Download PDF
                        </a>
                        </p>
                    </Col>
                </Row>
            </Col>
            <br/> 
            <Col span={24}> <h1> Dubai Commercial License </h1> </Col>
            <Col span={24}>
                <Row className="lin_card">
                    <Col span={2} className="lin_ani_div">
                        <LottieAnimation animationData={LicinAni} className="lin_ani" />
                    </Col>
                    <Col span={2}>
                        <Divider type="vertical" className="lin_divd" />
                    </Col>
                    <Col span={20}>
                        <h2 className="lin_main_txt"> XAD Technologies LLC</h2>
                        <p className="lin_sub_txt">
                            <a
                                href="https://www.xadtechnologies.com/wp-content/uploads/2021/06/DUBAI-TL.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Download PDF
                            </a>
                        </p>
                    </Col>
                </Row>
                <Row className="lin_card">
                    <Col span={2} className="lin_ani_div">
                        <LottieAnimation animationData={LicinAni} className="lin_ani" />
                    </Col>
                    <Col span={2}>
                        <Divider type="vertical" className="lin_divd" />
                    </Col>
                    <Col span={20}>
                        <h2 className="lin_main_txt"> Xad Electromechanical Trade license </h2>
                        <p className="lin_sub_txt"> 
                        <a
                            href="https://www.xadtechnologies.com/wp-content/uploads/2021/06/Xad-Electromechanical-Trade-license-22-10-2020.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Download PDF
                        </a>
                        </p>
                    </Col>
                </Row>
            </Col>
            <br/> 
            <Col span={24}> <h1> Sharjah Commercial License </h1> </Col>
            <Col span={24}>
                <Row className="lin_card">
                    <Col span={2} className="lin_ani_div">
                        <LottieAnimation animationData={LicinAni} className="lin_ani" />
                    </Col>
                    <Col span={2}>
                        <Divider type="vertical" className="lin_divd" />
                    </Col>
                    <Col span={20}>
                        <h2 className="lin_main_txt"> XAD Technologies LLC</h2>
                        <p className="lin_sub_txt">
                            <a
                                href="https://www.xadtechnologies.com/wp-content/uploads/2021/06/XAD-SHARJAH-TRADE-LICENSE.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Download PDF
                            </a>
                        </p>
                    </Col>
                </Row>
            </Col>
            <br/> 
            <Col span={24}> <h1> Pakistan Commercial License </h1> </Col>
            <Col span={24}>
                <Row className="lin_card">
                    <Col span={2} className="lin_ani_div">
                        <LottieAnimation animationData={LicinAni} className="lin_ani" />
                    </Col>
                    <Col span={2}>
                        <Divider type="vertical" className="lin_divd" />
                    </Col>
                    <Col span={20}>
                        <h2 className="lin_main_txt"> XAD Technologies LLC</h2>
                        <p className="lin_sub_txt">
                            <a
                                href="https://www.xadtechnologies.com/wp-content/uploads/2021/06/XAD-SHARJAH-TRADE-LICENSE.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Download PDF
                            </a>
                        </p>
                    </Col>
                </Row>
            </Col>
        </Row>
      </div>
      <FooterComponent normal={true} />
    </>
  );
};

export default LicensesPage;