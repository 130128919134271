import React from "react";
import { Layout, Row, Col, Select } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import "./style.css";
import Logo from "../../assets/images/logo.png";
import { KsaFlag, UsaFlag, UkFlag } from "../../assets/images";
import MenuPage from '../../pages/menu'
const { Header } = Layout;
const { Option } = Select;
const HeaderComponent = ({ menuVisible, toggleMenu, flag }) => {
  console.log("in child", menuVisible);
  return (
    <>
      <Header className="header_bg">
        <Row>
          <Col span={16}>
            <img src={Logo} alt="logo" />
          </Col>
          <Col span={8}>
            <Row>
              <Col span={8}>
                <Select
                  defaultValue="english"
                  className="lan_select"
                  placeholder="ENGLISH"
                >
                  <Option value="english"> ENGLISH </Option>
                  <Option value="urdu"> URDU </Option>
                </Select>
              </Col>
              <Col span={8}>
                <MenuOutlined
                  className="menu_icon"
                  onClick={() => toggleMenu()}
                />
              </Col>
              <Col span={8}>
                <img 
                  src={flag && flag === "usa" ? UsaFlag : flag === "uk" ? UkFlag : KsaFlag} 
                  alt="ksa_logo" 
                  className="flag_img" 
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Header>
      {menuVisible && <MenuPage onClose={toggleMenu} />}
    </>
  );
};
export default HeaderComponent;
