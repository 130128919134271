import React, {useState} from "react";
import { Row, Col } from 'antd'
import HeaderComponent from '../../components/header'
import {Enr1, Enr2} from '../../assets/images/energy'
import {AbuDubaiMap, DubaiMap, HistoryIcon, IslamabadMap, KsaMap, LahoreMap, MissionIcon, SharjahMap, StrengthIcon, UkMap, VisionIcon} 
    from '../../assets/images/we_are'
import {ArrowRight} from '../../assets/images'
import FooterComponent from "../../components/footer";
import LottieAnimation from "../../components/animation";
import {RippleAni} from '../../assets/images/animations'
import './style.css'
const WeArePage = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const toggleMenu = () => {
        console.log("menuVisible", menuVisible);
        setMenuVisible(!menuVisible);
    };
    return(
        <>
            <HeaderComponent flag={false} toggleMenu={toggleMenu} menuVisible={menuVisible}  /> 
            <div className="main_head_we">
                <Row>
                    <Col span={4} />
                    <Col span={11}>
                        <h1 className="fix_head_txt"> Who <span className="head_cay_txt"> We Are? </span> </h1>
                        <span className="head_sub_txt"> 
                        We are ISO-certified, providing innovative solutions in telecom, IT, managed services, and security for major clients like Etisalat, du, Nokia, Huawe, Expo 2020, and more across the UAE, Saudi Arabia, Pakistan, and the UK since 2006.
                        </span>
                    </Col>
                </Row>
                <Row className="brd_row">
                    <Col span={4} />
                    <Col span={11}>
                        <span className="head_brd_txt"> 
                           Home / Who We Are?
                        </span>
                    </Col>
                </Row>
            </div>
            <div>
                <Row className="center_div" >
                    <Col span={24}>
                        <h1 className="net_main_txt"> Innovative Solutions and Services: Xad Technologies LLC </h1>
                        <p className=""> Empowering Connectivity: Xad's ISO-Certified Telecom & IT Solutions</p>
                        <p>
                            Xad Technologies LLC, established in 2006 is an ISO & OHSAS Certified company, which has offices in UAE, Saudi Arabia and Pakistan. Xad offers Innovative Solutions and Services in Telecommunication, IT Networks, Managed Services, Staff Outsourcing, Fleet Management & Security areas. Xad is currently working with all major Telecom & IT Clients in UAE, Saudi Arabia, Pakistan and UK, i.e. Etisalat, du, Nokia, Huawei, Ericsson, Al Catel, Telenor, Dewa, Ministry of Defence, Expo 2020, RTA and other government and Non Government Organizations.
                        </p>
                    </Col>
                </Row>
                <Row className="center_div" gutter={[10,10]}>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Our History </h1>
                        <span> 
                            Xad Communications started in 2005 by a group of highly qualified academic researchers (Ph.D. holders from well known European Universities) and entrepreneurs to carry out research and development projects in Technology areas.
                            The group established Xad Technologies UAE in 2006 to offer innovative solutions and services to the Middle East Market.
                            The success lead to open 14+ branches in all other the Emirates in the UAE, Saudi Arabia, Pakistan and United Kingdom.
                        </span>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <img src={HistoryIcon} alt='icon' className="net_img" />
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <img src={StrengthIcon} alt='icon' className="net_img"  />
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Our Strengths </h1>
                        <span> 
                            <p>
                                <img src={ArrowRight}  alt='icon' className="arrow_icon" />
                                100 million dirham + Revenue annually
                            </p>
                            <p>
                                <img src={ArrowRight}  alt='icon' className="arrow_icon" />
                                15 years history with global presence in 14+ locations.
                            </p>
                            <ul>
                                <li> 2 millions customers served in different areas </li>
                                <li> 500 projects completion with prestigious clients</li>
                                <li> 3500+ staff on XAD employment with high retention rate. </li> 
                                <li> 780+ specialized vehicles </li> 
                                <li> Preferred partner and first choice for well known clients </li>
                                <li> Xad has strong financial capability backed by strategic investors.</li>
                                <li> Xad has commitment of expanding its business operations in UK, Canada, India, Egypt, Oman and African Countries in next 5 years.</li>
                            </ul>
                        </span>
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Our Vision </h1>
                        <span> 
                            <p>
                                <img src={ArrowRight}  alt='icon' className="arrow_icon" />
                                To be one of the top leading regional companies known for services, trust and innovative solutions in Telecom, IT Networks and Security Solutions.
                            </p>
                        </span>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <img src={VisionIcon} alt='icon' className="net_img" />
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <img src={MissionIcon} alt='icon' className="net_img"  />
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Our Mission </h1>
                        <span> 
                            <p>
                                <img src={ArrowRight}  alt='icon' className="arrow_icon" />
                                To be the first choice of stakeholders by providing desired solutions and services to a diverse portfolio of clients.
                            </p>
                            <ul>
                                <li> To introduce innovative solutions in Telecom, IT Networks, Communication and Security Sectors to the UAE and Regional Industry. </li>
                                <li> To promote research culture and contribute in the development of the country and the region. </li>
                            </ul>
                        </span>
                    </Col>

                </Row>
            </div>
            <div className="center_div">
                <Row>
                    <Col span={24} className="txt_center">
                        <h1> Organization’s Name</h1>
                        <span className="orgn_sub_txt"> Our International Office Locations </span>
                    </Col> 
                </Row>
                <div className="full_map_bg">
                    <LottieAnimation animationData={RippleAni} className="ripple1" />
                    <LottieAnimation animationData={RippleAni} className="ripple2" />
                    <LottieAnimation animationData={RippleAni} className="ripple3" />
                    <LottieAnimation animationData={RippleAni} className="ripple4" />
                </div>
            </div>
            <div className="center_div">
                <Row gutter={[10,10]}>
                    <Col span={12}>
                        <h1 className="add_main_txt"> Address </h1>
                        <p className="add_sub_txt"> Islamabad Address </p>
                        <p className="add_sub_txt_1"> Empire Center, 407, Gulberg, Islamabad, 46000, Pakistan</p>
                    </Col>
                    <Col span={12}>
                        <img src={IslamabadMap} alt="map" />
                    </Col>
                    <Col span={12}>
                        <h1 className="add_main_txt"> Address </h1>
                        <p className="add_sub_txt"> Lahore Address </p>
                        <p className="add_sub_txt_1"> F66Q+7J3, Sultan Town, Lahore, Punjab, Pakistan</p>
                    </Col>
                    <Col span={12}>
                        <img src={LahoreMap} alt="map" />
                    </Col>
                    <Col span={12}>
                        <h1 className="add_main_txt"> Address </h1>
                        <p className="add_sub_txt"> Dubai Address </p>
                        <p className="add_sub_txt_1"> 1308, Opal Tower, Marasi Drive, Business Bay, Dubai, UAE. P.O. Box No. 126732</p>
                    </Col>
                    <Col span={12}>
                        <img src={DubaiMap} alt="map" />
                    </Col>
                    <Col span={12}>
                        <h1 className="add_main_txt"> Address </h1>
                        <p className="add_sub_txt"> Abu Dhabi Address </p>
                        <p className="add_sub_txt_1"> 9FFX+CCG - Musaffah - M-14 Abu Dhabi</p>
                    </Col>
                    <Col span={12}>
                        <img src={AbuDubaiMap} alt="map" />
                    </Col>
                    <Col span={12}>
                        <h1 className="add_main_txt"> Address </h1>
                        <p className="add_sub_txt"> Sharjah Address </p>
                        <p className="add_sub_txt_1"> Mohammed Al Mulla Tower Al Ittihad St - Al Nahda Sharjah</p>
                    </Col>
                    <Col span={12}>
                        <img src={SharjahMap} alt="map" />
                    </Col>
                    <Col span={12}>
                        <h1 className="add_main_txt"> Address </h1>
                        <p className="add_sub_txt"> UK Address </p>
                        <p className="add_sub_txt_1"> 03 Park Lane Wembley, HA9 7RH</p>
                    </Col>
                    <Col span={12}>
                        <img src={UkMap} alt="map" />
                    </Col>
                    <Col span={12}>
                        <h1 className="add_main_txt"> Address </h1>
                        <p className="add_sub_txt"> KSA Address</p>
                        <p className="add_sub_txt_1"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis nunc a molestie dictum. </p>
                    </Col>
                    <Col span={12}>
                        <img src={KsaMap} alt="map" />
                    </Col>
                </Row>
            </div>
            <FooterComponent normal={true}  />
        </>
    )
}

export default WeArePage