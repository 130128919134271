/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import { Row, Col, Button, Card, Form, Input, Divider } from "antd";
import HeaderComponent from "../../components/header";
import {
  TickIcon,
  HandIcon,
  CarIcon,
  ArrowIcon,
  EarthIcon,
} from "../../assets/images/head";
import {
  FixNet,
  MobNet,
  HrOut,
  ManSer,
  ArrowLeft,
  ArrowRight,
  ProjectStatus,
  CarrerImg,
  CarrGif,
  DubaiIcon,
  SarjahIcon,
  AbuDubaiIcon,
  UkIcon,
  StarY,
  StarW,
  DirecIcon,
  NavIcon,
  MapIcon,
  MainUsa,
  MobileHead,
  MobileHeadTwo,
} from "../../assets/images";
import {
  TelecomIcon,
  SecurIcon,
  ManSerIcon,
  IctIcon,
  HrIcon,
  EnergyIcon,
} from "../../assets/images/expert_imgs";
import {
  Client1,
  Client2,
  Client3,
  Client4,
} from "../../assets/images/clients";
import {
  Etlisalat,
  Alstom,
  Du,
  Ericsson,
  Huawei,
  Nokia,
  Solar,
  Main,
} from "../../assets/images/projects";
import FooterComponent from "../../components/footer";
import { Link } from "react-router-dom";
import {
  HandAni,
  CarAni,
  ArrowAni,
  GlobeAni,
} from "../../assets/images/animations";
import LottieAnimation from "../../components/animation";
import "./style.css";
const { TextArea } = Input;
const HomeUsaPage = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [mapValue, setMapValue] = useState('dubai')
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };
  const mapClick = (val) => {
    console.log('map click ', val);
    setMapValue(val)
  }
  return (
    <>
      <HeaderComponent
        toggleMenu={toggleMenu}
        menuVisible={menuVisible}
        flag="usa"
      />
      <div className="main">
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
            <h1 className="main_head_txt"> Xad Technologies LLC UAE </h1>
            <div className="width_80">
              <span>
                Xad Technologies LLC, established in 2006, is an ISO & OHSAS
                Certified company with offices in UAE, Saudi Arabia, Pakistan,
                and the UK. Specializing in Telecom, IT, Managed Services, and
                more, Xad collaborates with major clients such as Etisalat, du,
                Nokia, and government organizations globally.
              </span>
            </div>
            <br />
            <br />
            <Button className="read_btn"> Read More</Button>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={8}
            xxl={8}
            className="main_img_di"
          >
            <img src={MainUsa} alt="main img" />
          </Col>
        </Row>
      </div>
      <div className="main_mobile">
        <div className="main_mobile_top">
          <Row justify="center">
            <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
              <h1 className="main_head_txt"> Xad Technologies LLC UAE </h1>
              <div className="width_80">
                <span style={{ color: "white" }}>
                  Xad Technologies LLC, established in 2006, is an ISO & OHSAS
                  Certified company with offices in UAE, Saudi Arabia, Pakistan,
                  and the UK. Specializing in Telecom, IT, Managed Services, and
                  more, Xad collaborates with major clients such as Etisalat,
                  du, Nokia, and government organizations globally.
                </span>
              </div>
              <br />
              <br />
              <Button className="read_btn"> Read More</Button>
            </Col>
          </Row>
        </div>
      </div>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={8}
        xl={8}
        xxl={8}
        className="main_img_div"
      >
        <img src={MobileHeadTwo} alt="main img" />
      </Col>

      <Row className="head" gutter={[8, 16]}>
        <Col xs={12} sm={12} md={12} lg={6} xx={6} xxl={6}>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={4}
              xl={4}
              xxl={4}
              className="txt_center"
            >
              <img src={TickIcon} alt="tick icon" className="" />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={20}
              xx={20}
              xxl={20}
              className="txt_center"
            >
              <span className="head_txt">
                Established 2006, <br /> Global Presence
              </span>
            </Col>
          </Row>
          {/* <div className="ani_div">
            <LottieAnimation animationData={HandAni} className="ani_icon" />
          </div>
          <span className="txt_center font"> 20 + </span>
          <span className="font"> World's Top Clients</span> */}
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} xx={6} xxl={6}>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={4}
              xl={4}
              xxl={4}
              className="txt_center"
            >
              <img src={TickIcon} alt="tick icon" />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={20}
              xx={20}
              xxl={20}
              className="txt_center"
            >
              <span className="head_txt">
                ISO & OHSAS <br /> Certified
              </span>
            </Col>
          </Row>
          {/* <div className="ani_div">
            <LottieAnimation animationData={CarAni} className="ani_icon" />
          </div>
          <span className="txt_center font"> 780 + </span>
          <span className="txt_center font"> Functional Vehicle</span> */}
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} xx={6} xxl={6}>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={4}
              xl={4}
              xxl={4}
              className="txt_center"
            >
              <img src={TickIcon} alt="tick icon" />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={20}
              xx={20}
              xxl={20}
              className="txt_center"
            >
              {/* <Col span={20}> */}
              <span className="head_txt">
                Innovative Telecom <br /> Solutions
              </span>
            </Col>
          </Row>
          {/* <div className="ani_div">
            <LottieAnimation animationData={ArrowAni} className="ani_icon" />
          </div>
          <span className="txt_center font"> 3,500 + </span>
          <span className="txt_center font"> Qualified Resources</span> */}
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} xx={6} xxl={6}>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={4}
              xl={4}
              xxl={4}
              className="txt_center"
            >
              <img src={TickIcon} alt="tick icon" />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={20}
              xx={20}
              xxl={20}
              className="txt_center"
            >
              <span className="head_txt">
                Major Clients <br /> Worldwide
              </span>
            </Col>
          </Row>
          {/* <div className="ani_div">
            <LottieAnimation animationData={GlobeAni} className="ani_icon" />
          </div>
          <span className="txt_center font"> 14 + </span>
          <span className="txt_center font"> Offices Globally</span> */}
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} xx={6} xxl={6}>
          <div className="ani_div">
            <LottieAnimation animationData={HandAni} className="ani_icon" />
          </div>
          <p className="txt_center font"> 20 + </p>
          <span className="txt_center font"> World's Top Clients</span>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} xx={6} xxl={6}>
          <div className="ani_div">
            <LottieAnimation animationData={CarAni} className="ani_icon" />
          </div>
          <p className="txt_center font"> 780 + </p>
          <span className="txt_center font"> Functional Vehicle</span>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} xx={6} xxl={6}>
          <div className="ani_div">
            <LottieAnimation animationData={ArrowAni} className="ani_icon" />
          </div>
          <p className="txt_center font"> 3,500 + </p>
          <span className="txt_center font"> Qualified Resources</span>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} xx={6} xxl={6}>
          <div className="ani_div">
            <LottieAnimation animationData={GlobeAni} className="ani_icon" />
          </div>
          <p className="txt_center font"> 14 + </p>
          <span className="txt_center font"> Offices Globally</span>
        </Col>
        <div className="com_txt_div">
          <span>
            Xad Technologies Leading the Future of <br />
            <span id="sc_txt"> Technology Excellence </span>
          </span>
        </div>
      </Row>
      <div className="head_1">
        <Row>
          <Col span={24} className="txt_center">
            <span className="head_1_txt">
              Why Clients not just choose us, <br /> they stay with us?
            </span>
          </Col>
          <Col span={24}>
            <Row className="pad_10">
              {/* <Col span={12}> */}
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
                className="txt_center"
              >
                <h1 className="head_1_txt_2">
                  <span className="ligt_blue_txt">
                    Innovating Across Continents
                  </span>
                  - 15 Years of Xad Technologies' Global Excellence.
                </h1>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
                className="txt_center"
              >
                <span className="head_1_txt_3">
                  With a rich history spanning over 15 years, Xad Technologies
                  LLC has amassed a wealth of proven experience in delivering
                  exceptional services to industry giants and esteemed
                  organizations globally. Our track record includes successful
                  collaborations with key telecommunications players such as
                  Etisalat and du, where we have consistently demonstrated our
                  prowess in providing cutting-edge solutions. Furthermore, our
                  strategic partnerships with leading technology providers such
                  as Nokia, Huawei, Alcatel, and Ericsson underscore our
                  commitment to staying at the forefront of innovation.
                </span>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="head_1_btm_btn_div">
            <Button className="read_btn"> Learn More About Us </Button>
          </Col>
        </Row>
      </div>
      <Row>
        <Col span={24}>
          <h1 className="ser_txt txt_center"> Services We Provide </h1>
        </Col>

        <Col span={24}>
          <Row className="ser_row" style={{ display: "flex" }}>
            {/* fix net */}
            <Row className="zoom-container"> 
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
                className="ser_col order_2"
              >
                  <Link to="/fixed_net">
                    <h2 className="ser_main_txt"> Fixed Network </h2>
                    <span className="ser_span_txt">
                      Experience a comprehensive suite of end-to-end, cost-effective
                      solutions meticulously designed to seamlessly expand and
                      enhance your fixed broadband network infrast...
                    </span>
                    <span className="ser_span_txt ser_hid_txt">
                      ructure. Our tailored offerings encompass a spectrum of innovative
                      technologies, strategic planning, and efficient
                      implementation, ensuring a robust and scalable network that
                      aligns with your business objectives while maintaining a focus
                      on affordability and long-term sustainability.
                    </span>
                  </Link>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="ser_img_div txt_center order_1"
                >
                  <div className="ser_img_cent_div">
                    <img src={FixNet} alt="fix net" className="ser_img" />
                  </div>
                </Col>
            </Row>
            {/* mob net */}
            <Row className="zoom-container"> 
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
                className="ser_img_div txt_center order_3"
              >
                <div className="ser_img_cent_div">
                  <img src={MobNet} alt="fix net" className="ser_img" />
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
                className="ser_col order_4"
              >
                <Link to="/mobile_net" className="dis_fl">
                  <h2 className="ser_main_txt"> Mobile Network </h2>
                  <span className="ser_span_txt">
                    Unlock the convenience of a single, all-encompassing
                    destination for a diverse range of solutions, covering
                    Wireless, In-Building Solutions (IBS), and GSM Networks. 
                  </span>
                  <span className="ser_span_txt ser_hid_txt">
                    Our comprehensive suite of services not only streamlines your
                    connectivity requirements but also offers a holistic approach,
                    providing expertise across the spectrum—from wireless
                    connectivity solutions to state-of-the-art in-building systems
                    and robust GSM network solutions. With a commitment to
                    delivering excellence, we stand as your trusted partner,
                    ensuring a unified, seamlessly integrated approach for all
                    your network needs.
                  </span>
                </Link>
              </Col>
            </Row>
          </Row>

          <Row className="ser_row" style={{ display: "flex" }}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              className="txt_center order_2"
            >
              <Link to="/manage" className="dis_fl">
                <h2 className="ser_main_txt"> Managed Services </h2>
                <span className="ser_span_txt">
                  Enhance your business with our comprehensive, market-leading
                  solutions. Tailored for diverse industries, our services
                  integrate seamlessly into your workflows, from project
                  management to cutting-edge technologies. Trust our commitment
                  to excellence for sustained success and growth
                </span>
              </Link>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              className="ser_img_div txt_center order_1"
            >
              <div className="zoom-container">
                <img src={ManSer} alt="fix net" className="ser_img" />
              </div>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              className="ser_img_div txt_center order_3"
            >
              <div className="zoom-container">
                <img src={HrOut} alt="fix net" className="ser_img" />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              className="txt_center order_4"
            >
              <Link to="/hr_out" className="dis_fl">
                <h2 className="ser_main_txt"> HR Outsourcing </h2>
                <span className="ser_span_txt">
                  Immerse yourself in the versatility of our services as we
                  present a flexible and tailor-made staffing solution
                  meticulously designed to cater to your unique organizational
                  needs. Our commitment lies in understanding the intricacies of
                  your staffing requirements, allowing us to offer a
                  personalized approach that seamlessly aligns with your
                  business goals. From crafting dynamic workforce strategies to
                  providing adaptable staffing solutions, our team is dedicated
                  to ensuring that your staffing challenges are not just met but
                  surpassed, fostering a collaborative environment that promotes
                  sustained success and organizational growth.
                </span>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="head_2">
        <Row>
          <Col span={24} align="middle" className="text-center">
            <h1 className="head_2_main_txt"> We Are Expert In </h1>
            <span className="head_2_sub_txt">
              Simplifying Solutions with Precision and Proficiency
            </span>
          </Col>
        </Row>
        <Row gutter={[32, 32]} className="center_div pt-5">
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Row gutter={[8, 8]} className="mr_5">
              <Col xs={10} sm={9} md={6} lg={6} xl={6} xxl={6}>
                <img src={TelecomIcon} alt="telecom icon" />
              </Col>
              <Col xs={14} sm={15} md={18} lg={18} xl={18} xxl={18}>
                <h2 className="sol_main_txt">
                  <span className="sol_blue"> Telecom </span> Solutions
                </h2>
                <span className="sol_sup_txt">
                  World-class, award winning telecom fixed and mobile network
                  solutions.
                </span>
              </Col>
            </Row>
            <Row className="mr_5">
              <Col xs={10} sm={9} md={6} lg={6} xl={6} xxl={6}>
                <img src={IctIcon} alt="telecom icon" />
              </Col>
              <Col xs={14} sm={15} md={18} lg={18} xl={18} xxl={18}>
                <h2 className="sol_main_txt">
                  <span className="sol_blue"> ICT </span> Solutions
                </h2>
                <span className="sol_sup_txt">
                  Cutting edge technological and digital transformation
                  solutions.
                </span>
              </Col>
            </Row>

            <Row className="mr_5">
              <Col xs={10} sm={9} md={6} lg={6} xl={6} xxl={6}>
                <img src={EnergyIcon} alt="telecom icon" />
              </Col>
              <Col xs={14} sm={15} md={18} lg={18} xl={18} xxl={18}>
                <h2 className="sol_main_txt">
                  <span className="sol_blue"> Energy </span> Solutions
                </h2>
                <span className="sol_sup_txt">
                  Harness the environments energy to fulfill your energy needs.
                </span>
              </Col>
            </Row>

            {/* <Row className="mr_5">
            <Col span={6}>
              <img src={SecurIcon} alt="telecom icon" />
            </Col>
            <Col span={18}>
              <h2 className="sol_main_txt">
                <span className="sol_blue"> Security </span> Solutions
              </h2>
              <span className="sol_sup_txt">
                Protecting your assets with network and cyber security
                solutions.
              </span>
            </Col>
          </Row>
          <Row className="mr_5">
            <Col span={6}>
              <img src={ManSerIcon} alt="telecom icon" />
            </Col>
            <Col span={18}>
              <h2 className="sol_main_txt">
                <span className="sol_blue"> Managed </span> Services
              </h2>
              <span className="sol_sup_txt">
                Managing network systems, facilities, projects and transport
                services.
              </span>
            </Col>
          </Row>
          <Row className="mr_5">
            <Col span={6}>
              <img src={HrIcon} alt="telecom icon" />
            </Col>
            <Col span={18}>
              <h2 className="sol_main_txt">
                <span className="sol_blue"> HR </span> Outsourcing
              </h2>
              <span className="sol_sup_txt">
                Meeting your specialized manpower recruitment needs.
              </span>
            </Col>
          </Row> */}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Row className="mr_5">
              <Col xs={10} sm={9} md={6} lg={6} xl={6} xxl={6}>
                <img src={SecurIcon} alt="telecom icon" />
              </Col>
              <Col xs={14} sm={15} md={18} lg={18} xl={18} xxl={18}>
                <h2 className="sol_main_txt">
                  <span className="sol_blue"> Security </span> Solutions
                </h2>
                <span className="sol_sup_txt">
                  Protecting your assets with network and cyber security
                  solutions.
                </span>
              </Col>
            </Row>

            <Row className="mr_5">
              <Col xs={10} sm={9} md={6} lg={6} xl={6} xxl={6}>
                <img src={ManSerIcon} alt="telecom icon" />
              </Col>
              <Col xs={14} sm={15} md={18} lg={18} xl={18} xxl={18}>
                <h2 className="sol_main_txt">
                  <span className="sol_blue"> Managed </span> Services
                </h2>
                <span className="sol_sup_txt">
                  Managing network systems, facilities, projects and transport
                  services.
                </span>
              </Col>
            </Row>
            <Row className="mr_5">
              <Col xs={10} sm={9} md={6} lg={6} xl={6} xxl={6}>
                <img src={HrIcon} alt="telecom icon" />
              </Col>
              <Col xs={14} sm={15} md={18} lg={18} xl={18} xxl={18}>
                <h2 className="sol_main_txt">
                  <span className="sol_blue"> HR </span> Outsourcing
                </h2>
                <span className="sol_sup_txt">
                  Meeting your specialized manpower recruitment needs.
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="head_3">
        <Row>
          <Col span={24} align="middle" className="text-center">
            <h1 className="top_clnt_txt"> Our Top Clients </h1>
            <span className="top_sub_txt">
              Expand your business Horizons with XAD strategic alliance.
            </span>
          </Col>
        </Row>
        <Row className="pb_8">
          <Col span={4} className="arrow_div">
            <img src={ArrowLeft} alt="letf " />
          </Col>
          <Col span={16}>
            <Row className="client_div">
              {/* <Col span={6} className="client__col_div">
                <img src={Client1} alt="clietnt 1" />
                <h2 className="client_main_text"> Wipro </h2>
                <span className="client_sub_txt">
                  Global IT Solutions, Driving Business Transformation.
                  {/* <br />
                  <br /> 
                </span>
              </Col> */}
              <Col xs={24} sm={12} md={6} className="client__col_div">
                <img src={Client1} alt="clietnt 1" />
                <h2 className="client_main_text"> Wipro </h2>
                <span className="client_sub_txt">
                  Global IT Solutions, Driving Business Transformation.
                </span>
              </Col>
              <Col xs={24} sm={12} md={6} className="client__col_div">
                <img src={Client2} alt="clietnt 1" />
                <h2 className="client_main_text"> Alcatel-Lucent </h2>
                <span className="client_sub_txt">
                  Transforming Communications with Innovative Technology
                  Solutions.
                </span>
              </Col>
              <Col xs={24} sm={12} md={6} className="client__col_div">
                <img src={Client3} alt="clietnt 1" />
                <h2 className="client_main_text"> Alstom </h2>
                <span className="client_sub_txt">
                  Innovative Transportation Solutions, Global Industry Leader.
                </span>
              </Col>
              <Col xs={24} sm={12} md={6} className="client__col_div">
                <img src={Client4} alt="clietnt 1" />
                <h2 className="client_main_text"> Cisco </h2>
                <span className="client_sub_txt">
                  Networking Solution for Connecting the Digital World.
                </span>
              </Col>
              <Col xs={24} sm={12} md={6} className="client__col_div">
                <img src={Client1} alt="clietnt 1" />
                <h2 className="client_main_text"> Wipro </h2>
                <span className="client_sub_txt">
                  Global IT Solutions, Driving Business Transformation.
                </span>
              </Col>
              <Col xs={24} sm={12} md={6} className="client__col_div">
                <img src={Client1} alt="clietnt 1" />
                <h2 className="client_main_text"> Wipro </h2>
                <span className="client_sub_txt">
                  Global IT Solutions, Driving Business Transformation.
                </span>
              </Col>
              <Col xs={24} sm={12} md={6} className="client__col_div">
                <img src={Client1} alt="clietnt 1" />
                <h2 className="client_main_text"> Wipro </h2>
                <span className="client_sub_txt">
                  Global IT Solutions, Driving Business Transformation.
                </span>
              </Col>
              <Col xs={24} sm={12} md={6} className="client__col_div">
                <img src={Client1} alt="clietnt 1" />
                <h2 className="client_main_text"> Wipro </h2>
                <span className="client_sub_txt">
                  Global IT Solutions, Driving Business Transformation.
                </span>
              </Col>
            </Row>
          </Col>
          <Col span={4} className="arrow_div">
            <img src={ArrowRight} alt="letf " />
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          <Col span={24} align="middle" className="text-center">
            <h1 className="pro_main_txt"> Our Projects </h1>
            <span className="pro_sub_txt">
              Explore the diverse portfolio of Our Projects, where innovation
              meets execution.
            </span>
          </Col>
          <Col span={24}>
            <Row className="center_div cont_centr" gutter={[20, 20]}>
              <Col
                xs={24}
                sm={12}
                md={8}
                lg={4}
                xl={4}
                xxl={4}
                className="txt_center"
              >
                <img src={Etlisalat} alt="p1" className="width_100" />
                <h2 className="pro_blk_txt"> Etisalat </h2>
                <span className="pro_gry_txt">
                  A leading telecom company providing connectivity solutions
                  worldwide.
                </span>
              </Col>
              <Col
                xs={24}
                sm={12}
                md={8}
                lg={4}
                xl={4}
                xxl={4}
                className="txt_center"
              >
                <img src={Du} alt="p1" className="width_100" />
                <h2 className="pro_blk_txt"> DU Project </h2>
                <span className="pro_gry_txt">
                  UAE's pioneering telecom delivering seamless connectivity.
                </span>
              </Col>
              <Col
                xs={24}
                sm={12}
                md={8}
                lg={4}
                xl={4}
                xxl={4}
                className="txt_center"
              >
                <img src={Nokia} alt="p1" className="width_100" />
                <h2 className="pro_blk_txt"> Nokia </h2>
                <span className="pro_gry_txt">
                  A global leader in technology, shaping the future with
                  connectivity solutions.
                </span>
              </Col>
              <Col
                xs={24}
                sm={12}
                md={8}
                lg={4}
                xl={4}
                xxl={4}
                className="txt_center"
              >
                <img src={Huawei} alt="p1" className="width_100" />
                <h2 className="pro_blk_txt"> Huawei </h2>
                <span className="pro_gry_txt">
                  Driving technological innovation with global connectivity
                  solutions.
                </span>
              </Col>
              <Col
                xs={24}
                sm={12}
                md={8}
                lg={4}
                xl={4}
                xxl={4}
                className="txt_center"
              >
                <img src={Alstom} alt="p1" className="width_100" />
                <h2 className="pro_blk_txt"> Alstom </h2>
                <span className="pro_gry_txt">
                  Powering transportation with cutting-edge rail and mobility
                  solutions.
                </span>
              </Col>
            </Row>
            <Row className="center_div cont_centr" gutter={[20, 20]}>
              <Col
                xs={24}
                sm={12}
                md={8}
                lg={4}
                xl={4}
                xxl={4}
                className="txt_center"
              >
                <img src={Ericsson} alt="p1" className="width_100" />
                <h2 className="pro_blk_txt"> Ericsson </h2>
                <span className="pro_gry_txt">
                  Pioneering global connectivity through innovative telecom
                  solutions.
                </span>
              </Col>
              <Col xs={24} sm={12} md={8} lg={12} xl={12} xxl={12}>
                <img src={Main} alt="ma " className="width_100" />
              </Col>
              <Col
                xs={24}
                sm={12}
                md={8}
                lg={4}
                xl={4}
                xxl={4}
                className="txt_center"
              >
                <img src={Solar} alt="p1" className="width_100" />
                <h2 className="pro_blk_txt"> Solar Projects </h2>
                <span className="pro_gry_txt">
                  Empowering a sustainable future through innovative solar
                  energy solutions
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div>
        <Row gutter={[8, 40]}>
          <Col span={12} className="skill_bg">
            <Row>
              <Col span={24} className="skill_pad">
                <h2 className="skill_main_txt"> OUR SKILLS </h2>
                <span className="skill_sub_txt">
                  <span className="ligt_blue_txt">
                    You Cannot Create Experience
                  </span>
                  - You Must Undergo it.
                </span>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <img src={ProjectStatus} alt="project " className="width_65" />
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          <Col span={24} align="middle" className="text-center">
            <h1> Looking For Challenging Career </h1>
            <span>
              Xad Technologies always looking for professions in the area of
              Telecom, Networking, and Infrastructure Services
            </span>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <img src={CarrerImg} alt="carr img " className="map" />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={12}
            className="carr_bg"
          >
            <div className="carr_txt_div">
              <span>
                At Xad Technologies, we're actively seeking skilled
                professionals in Telecom, Networking, and Infrastructure
                Services. Join a collaborative team dedicated to innovative
              </span>

              <br />
              <span className="hover_span sol_blue"> SEE MORE </span>
              <span className="hidd_txt">
                projects, where your expertise contributes to cutting-edge
                technology and impactful solutions. Your career with us is an
                opportunity to thrive in a dynamic and rewarding environment,
                shaping the future of telecommunications and infrastructure.
              </span>
            </div>
            <Row className="">
              <Col span={6}>
                <Button className="apply_btn"> Apply Now </Button>
              </Col>
              <Col span={18}>
                <img src={CarrGif} alt="gif test " className="com_img" />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          <Col span={24} align="middle" className="text-center">
            <h2> Global Presence </h2>
            <span> Our International Office Locations </span>
          </Col>
          <Col span={24} className="center_div">
            <Row gutter={[10, 10]}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <h2> Offices Location </h2>
                <span> Our Offices Locations World Wide </span>
                <Card className="loc_div">
                  <Row gutter={[8, 8]}>
                    <Col span={5} className="img">
                      <img src={DubaiIcon} alt="dubai_icon" />
                    </Col>
                    <Col span={14} className="img1">
                      <h2 className="cursor_click" onClick={() => mapClick('dubai')}> Xad Technologies LLC Dubai Office </h2>
                      <span>
                        Opal Tower - Office 1308 - Dubai - United Arab Emirates
                      </span>
                      <div>
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarW} alt="sss" />
                      </div>
                    </Col>
                    <Col span={5} className="nav_div">
                      <img src={NavIcon} alt="na " />
                      <img src={DirecIcon} alt="na " className="dir" />
                    </Col>
                  </Row>
                </Card>
                <Card className="loc_div">
                  <Row gutter={[8, 8]}>
                    <Col span={5} className="img">
                      <img src={SarjahIcon} alt="dubai_icon" />
                    </Col>
                    <Col span={14} className="img1">
                      <h2 className="cursor_click" onClick={() => mapClick('sharjah')}> Xad Technologies LLC Sharjah Office </h2>
                      <span>
                        Mohammed Al Mulla Tower - Al Ittihad St - Al Nahda -
                        Sharjah - United Arab Emirates
                      </span>
                      <div>
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarW} alt="sss" />
                      </div>
                    </Col>
                    <Col span={5} className="nav_div">
                      <img src={NavIcon} alt="na " />
                      <img src={DirecIcon} alt="na " className="dir" />
                    </Col>
                  </Row>
                </Card>
                <Card className="loc_div">
                  <Row gutter={[8, 8]}>
                    <Col span={5} className="img">
                      <img src={AbuDubaiIcon} alt="dubai_icon" />
                    </Col>
                    <Col span={14} className="img1">
                      <h2 className="cursor_click" onClick={() => mapClick('abudubai')}> Xad Technologies LLC Abu Dhabi Office </h2>
                      <span> 9FFX+CCG - Musaffah - M-14 - Abu Dhabi </span>
                      <div>
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarW} alt="sss" />
                      </div>
                    </Col>
                    <Col span={5} className="nav_div">
                      <img src={NavIcon} alt="na " />
                      <img src={DirecIcon} alt="na " className="dir" />
                    </Col>
                  </Row>
                </Card>
                <Card className="loc_div">
                  <Row gutter={[8, 8]}>
                    <Col span={5} className="img">
                      <img src={UkIcon} alt="dubai_icon" />
                    </Col>
                    <Col span={14} className="img1">
                      <h2 className="cursor_click" onClick={() => mapClick('uk')}> Xad Technologies LLC UK Office </h2>
                      <span> 03 Park Lane Wembley, HA9 7RH</span>
                      <div>
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarW} alt="sss" />
                      </div>
                    </Col>
                    <Col span={5} className="nav_div">
                      <img src={NavIcon} alt="na " />
                      <img src={DirecIcon} alt="na " className="dir" />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col
                className="map"
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
              >
                {mapValue === 'dubai' ? 
                <iframe 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28742765.169771045!2d38.64495437460305!3d28.4301681516848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5c8eeb676065%3A0xbba5f6cf98a2df1a!2sXad%20Technologies%20LLC!5e0!3m2!1sen!2s!4v1715585807205!5m2!1sen!2s" 
                    width="100%" 
                    height="750" 
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade"
                    style={{border: '0px'}}
                />
                : mapValue === 'sharjah' ? 
                <iframe 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28742765.169771045!2d38.64495437460305!3d28.4301681516848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5c78508232cf%3A0x4f982fb9ac7ab568!2sMohammed%20Al%20Mulla%20Tower%20-%20Al%20Ittihad%20St%20-%20Al%20Nahda%20-%20Sharjah%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1715585943594!5m2!1sen!2s" 
                  width="100%" 
                  height="750" 
                  allowfullscreen="" 
                  loading="lazy" 
                  referrerpolicy="no-referrer-when-downgrade"
                  style={{border: '0px'}}
                /> 
               
                :  mapValue === 'abudubai' ? 
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3634.2224872438082!2d54.498578099999996!3d24.373562500000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e4119a3c5ad2d%3A0xd0cd0eb12e4096b0!2s9FFX%2BCCG%20-%20Musaffah%20-%20M-14%20-%20Abu%20Dhabi%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1715586271640!5m2!1sen!2s" 
                  width="100%" 
                  height="750"  
                  allowfullscreen="" 
                  loading="lazy" 
                  referrerpolicy="no-referrer-when-downgrade"
                  style={{border: '0px'}}
                />
                
                :mapValue === 'uk' ? 
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2480.7767058955797!2d-0.29277980000000003!3d51.553993399999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876122da00c2165%3A0xecaa7c9dc0209002!2s3%20Park%20Ln%2C%20Wembley%20HA9%207RH%2C%20UK!5e0!3m2!1sen!2s!4v1715586367540!5m2!1sen!2s" 
                  width="100%" 
                  height="750" 
                  allowfullscreen="" 
                  loading="lazy" 
                  referrerpolicy="no-referrer-when-downgrade"
                />
                :null }
                
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          <Col span={24} align="middle" className="text-center">
            <Divider className="msg_divid" />
            <h2> Drop Us a Message </h2>
            <span>
              Feel free to get in touch with us at any time. our dedicated team
              is here and eager to assist you.
            </span>
          </Col>
        </Row>
        <Row className="center_div">
          <Col span={24} className="ss">
            <div className="cont_bg">
              <Form>
                <Row gutter={[10, 10]}>
                  <Col span={12}>
                    <Form.Item>
                      <Input className="txt_input" placeholder="Full Name *" />
                    </Form.Item>
                    <Form.Item>
                      <Input className="txt_input" placeholder="Email *" />
                    </Form.Item>
                    <Form.Item>
                      <Input className="txt_input" placeholder="Subject" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item>
                      <TextArea
                        style={{
                          resize: "none",
                        }}
                        className="txt_area"
                        rows={9}
                        placeholder="Your Message"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} className="send_btn">
                    <Button className="snd_btn"> Send Message </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
      <FooterComponent />
      {/* {menuVisible && <MenuPage onClose={toggleMenu} />} */}
    </>
  );
};
export default HomeUsaPage;
