// NokiaIcon
import React, { useState } from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import HeaderComponent from "../../components/header";
import FooterComponent from "../../components/footer";
import {
    ArrowLeft,
    ArrowRight,
} from "../../assets/images";
import {
    Client1,
    Client2,
    Client3,
    Client4,
} from "../../assets/images/clients";
import "./style.css";
const ClientPage = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const toggleMenu = () => {
    console.log("menuVisible", menuVisible);
    setMenuVisible(!menuVisible);
  };
  return (
    <>
      <HeaderComponent
        flag={false}
        toggleMenu={toggleMenu}
        menuVisible={menuVisible}
      />
      <div className="main_head_client">
        <Row>
          <Col span={4} />
          <Col span={11}>
            <h1 className="fix_head_txt">
            Major <p className="head_cay_txt"> Clients </p>
            </h1>
            <span className="head_sub_txt">
                We Lead the charge in innovation for major clients including Nokia, DU, Alstom, Huawei, Etisalat, Ericsson, and RTA, spanning telecommunications, solar initiatives, and infrastructure projects.
            </span>
          </Col>
        </Row>
        <Row className="brd_row">
          <Col span={4} />
          <Col span={11}>
            <span className="head_brd_txt">Home / Major Clients</span>
          </Col>
        </Row>
      </div>
      <div className="head_3">
        <Row>
          <Col span={24} align="middle" className="text-center">
            <h1 className="top_clnt_txt"> Our Major Clients </h1>
            <span className="top_sub_txt">
                Expand your business Horizons with XAD strategic alliance.
            </span>
          </Col>
        </Row>
        <Row className="pb_8">
          <Col span={4} className="arrow_div">
            <img src={ArrowLeft} alt="letf " />
          </Col>
          <Col span={16}>
            <Row className="client_div">
              <Col span={6} className="client__col_div">
                <img src={Client1} alt="clietnt 1" />
                <h2 className="client_main_text"> Alcatel-Lucent </h2>
                <span className="client_sub_txt">
                    Transforming Communications with Innovative Technology Solutions.
                </span>
              </Col>
              <Col span={6} className="client__col_div">
                <img src={Client2} alt="clietnt 1" />
                <h2 className="client_main_text"> Alstom </h2>
                <span className="client_sub_txt">
                    Powering transportation with cutting-edge rail and mobility solutions.
                </span>
              </Col>
              <Col span={6} className="client__col_div">
                <img src={Client3} alt="clietnt 1" />
                <h2 className="client_main_text"> Ericsson </h2>
                <span className="client_sub_txt">
                    Pioneering global connectivity through innovative telecom solutions.
                </span>
              </Col>
              <Col span={6} className="client__col_div">
                <img src={Client4} alt="clietnt 1" />
                <h2 className="client_main_text"> Etisalat </h2>
                <span className="client_sub_txt">
                    A leading telecom company providing connectivity solutions worldwide.
                </span>
              </Col>
              <Col span={6} className="client__col_div">
                <img src={Client1} alt="clietnt 1" />
                <h2 className="client_main_text"> Wipro </h2>
                <span className="client_sub_txt">
                  Global IT Solutions, Driving Business Transformation.
                </span>
              </Col>
              <Col span={6} className="client__col_div">
                <img src={Client1} alt="clietnt 1" />
                <h2 className="client_main_text"> Wipro </h2>
                <span className="client_sub_txt">
                  Global IT Solutions, Driving Business Transformation.
                </span>
              </Col>
              <Col span={6} className="client__col_div">
                <img src={Client1} alt="clietnt 1" />
                <h2 className="client_main_text"> Wipro </h2>
                <span className="client_sub_txt">
                  Global IT Solutions, Driving Business Transformation.
                </span>
              </Col>
              <Col span={6} className="client__col_div">
                <img src={Client1} alt="clietnt 1" />
                <h2 className="client_main_text"> Wipro </h2>
                <span className="client_sub_txt">
                  Global IT Solutions, Driving Business Transformation.
                </span>
              </Col>
            </Row>
          </Col>
          <Col span={4} className="arrow_div">
            <img src={ArrowRight} alt="letf " />
          </Col>
        </Row>
      </div>
      <FooterComponent normal={true} />
    </>
  );
};

export default ClientPage;