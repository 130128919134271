import React, {useState} from "react";
import { Row, Col } from 'antd'
import HeaderComponent from '../../components/header'
import FooterComponent from '../../components/footer'
import {T1, T2, T3} from '../../assets/images/torus'
import {Inno1, Inno2} from '../../assets/images/innomix'
import {ArrowRight} from '../../assets/images'
import './style.css'
const InnomixPage = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const toggleMenu = () => {
        console.log("menuVisible", menuVisible);
        setMenuVisible(!menuVisible);
    };
    return(
        <>
            <HeaderComponent flag={false} toggleMenu={toggleMenu} menuVisible={menuVisible} /> 
            <div className="main_head_inno">
                <Row>
                    <Col span={4} />
                    <Col span={11}>
                        <h1 className="fix_head_txt"> Innomix <br/> Manufacturing <span className="head_cay_txt"> LLC </span> </h1>
                        <span className="head_sub_txt"> 
                            Dubai's premier supplier of high-quality concrete products for vital projects, including Ready Mix Concrete, Masonry Blocks, and interlocks.
                        </span>
                    </Col>
                </Row>
                <Row className="brd_row">
                    <Col span={4} />
                    <Col span={11}>
                        <span className="head_brd_txt"> 
                           Home / Innomix Manufacturing LLC
                        </span>
                    </Col>
                </Row>
            </div>
            <div>
                <Row className="center_div" gutter={[10,10]}>
                    <Col span={24}>
                        <h1 className="net_main_txt"> Construction Material Manufacturing </h1>
                        <p className="net_sub_txt" > Ready mix Concrete & Masonry Block Manufacturing </p>
                        <p className="net_sub_txt">
                            A ready mix Concrete company registered in Dubai to supply high quality concrete products to various important projects. InnoMix Manufactures Construction Material containing Ready Mix Concrete project, Masanory Blocks and interlocks etc.
                        </p>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Phase 1 </h1>
                        <span> 
                            <p>
                                <img src={ArrowRight}  alt='icon' className="icon_width"  />
                                Hollow Blocks
                            </p>
                            <p>
                                <img src={ArrowRight}  alt='icon' className="icon_width"  />
                                Solid Blocks
                            </p>
                            <ul>
                                <li> Houridi Blocks </li>
                                <li> Interlocks </li>
                                <li> Uni Blocks </li>
                                <li> Rectangle Blocks </li>
                                <li> Thermal Blocks Etc </li>
                                <li> Light Weight Blocks </li>
                            </ul>
                        </span>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <img src={Inno1} alt='icon' className="net_img" />
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <img src={Inno2} alt='icon' className="net_img"  />
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Phase 2 </h1>
                        <span> 
                            <p> 
                                <img src={ArrowRight}  alt='icon' className="icon_width" />
                                Asphalt Plant (Torus)
                            </p>
                            <p>
                                <img src={ArrowRight}  alt='icon' className="icon_width" />
                                Recycling Plant (JV with US Co.)
                            </p>
                            <ul>
                                <li> Steel Fabrication Plant (JV with a UAE Co.) </li>
                                <li> Frozen Food Manufacturing (JV with a Local Co) </li>
                                <li> Plastic Products (Torus) </li>
                            </ul>
                        </span>
                    </Col>
                </Row>
            </div>
            <FooterComponent normal={true}  />
        </>
    )
}

export default InnomixPage