import React, {useState} from "react";
import { Row, Col } from 'antd'
import HeaderComponent from '../../components/header'
import {Hr1, Hr2} from '../../assets/images/hr'
import {ArrowRight} from '../../assets/images'
import FooterComponent from "../../components/footer";
import './style.css'
const HrPage = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const toggleMenu = () => {
        console.log("menuVisible", menuVisible);
        setMenuVisible(!menuVisible);
    };
    return(
        <>
            <HeaderComponent flag={false} toggleMenu={toggleMenu} menuVisible={menuVisible}  /> 
            <div className="main_head_hr">
                <Row>
                    <Col span={4} />
                    <Col span={11}>
                        <h1 className="fix_head_txt"> HR <br /> <span className="head_cay_txt"> Outsourcing </span> </h1>
                        <span className="head_sub_txt"> 
                            Explore our versatile, tailor-made staffing solutions for organizational success. From dynamic workforce strategies to adaptable solutions, we've got you covered.
                        </span>
                    </Col>
                </Row>
                <Row className="brd_row">
                    <Col span={4} />
                    <Col span={11}>
                        <span className="head_brd_txt"> 
                           Home / HR Outsourcing
                        </span>
                    </Col>
                </Row>
            </div>
            <div>
                <Row className="center_div" gutter={[10,10]}>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> HR Recruitment & Consultancy </h1>
                        <span> 
                            Recruitment of Manpower and supply to prestigious clients from India, Pakistan, Sri Lanka, Bangladesh, Nepal, Philippine, Egypt, Jordan and Lebanon. Currently have supplied 1500 engineers and technical staff.
                            <ul>
                                <li> Engineers & highly skilled Manpower Appointment and supply </li>
                                <li> Semi Skilled Staff i.e. Technicians </li>
                                <li>Blue Collar, non skilled staff supply </li>
                            </ul>
                        </span>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <img src={Hr1} alt='icon' className="net_img" />
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <img src={Hr2} alt='icon' className="net_img"  />
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Client Human Resources Management </h1>
                        <span> 
                            Highly Experienced, Skilled and Blue Collar Staff Recruitment and Placement for Prestigious Clients.
                            <ul>
                                <li> Client Resources Management </li>
                                <li> Recruitment on behalf of Client </li>
                                <li> Performance Management </li>
                                <li> Grievances Management </li>
                            </ul>
                        </span>
                    </Col>
                </Row>
            </div>
            <FooterComponent normal={true}  />
        </>
    )
}

export default HrPage