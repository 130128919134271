import React from "react";
import {Row, Col, Divider} from 'antd'
import {FooterLogo} from '../../assets/images'
import {
    FbIcon,
    PinIcon,
    TwitterIcon,
    YtIcon,
    PhoneIcon,
    MailIcon,
  } from "../../assets/images/footer_icons";
const FooterComponent = (props) => {
    return(
        <div className="footer_bg" style={{ marginTop: "30px" }}>
        <Row className={props.normal ? "center_div" : "center_div sss"}>
          <Col span={6}>
            <div className="fot_logo">
              <img src={FooterLogo} alt="logo" className="width_50" />
              <span className="footer_txt_14">
                1308, Opal Tower, Marasi Drive, Business Bay, Dubai, UAE. P.O.
                Box No. 126732
              </span>
            </div>
            <div className="socl_icn">
              <img src={FbIcon} alt="fb icon" />
              <img src={TwitterIcon} alt="fb icon" />
              <img src={YtIcon} alt="fb icon" />
              <img src={PinIcon} alt="fb icon" />
            </div>
            <div className="footer_txt">
              <span className="footer_tx">
                © Copyright 2021 XAD Technologies All Rights Reserved.
              </span>
            </div>
          </Col>
          <Divider type="vertical" />

          <Col span={6} className="fty_link">
            <span className="footer_txt_16"> Home </span>
            <span className="footer_txt_16"> Products & Services </span>
            <span className="footer_txt_16"> Projects </span>
            <span className="footer_txt_16"> Licenses </span>
            <span className="footer_txt_16"> Gallery & Download </span>
            <span className="footer_txt_16"> Contact Us </span>
          </Col>
          <Divider type="vertical" />
          <Col span={6} className="s">
            <div className="mtb-10">
              <img src={PhoneIcon} alt="icon" className="ftr_im" />
              <span className="footer_txt_14 ftr_img">
                Phone:
                <span className="footer_txt_1 ftr_img"> +7 499 503 03 03 </span>
              </span>
            </div>
            <div className="mtb-10">
              <img src={MailIcon} alt="icon" className="ftr_im" />
              <span className="footer_txt_14 ftr_img">
                Email:
                <span className="footer_txt_1 ftr_img">
                  {" "}
                  info@xadtechnologies.com{" "}
                </span>
              </span>
            </div>
            <div>
              <span className="footer_txt_14 plf_10">
                Do you have any questions? Send us an e-mail and we will reply
                to you as soon as possible.
              </span>
            </div>
          </Col>         
        </Row>
      </div>
    )
}
export default FooterComponent