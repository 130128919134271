import React, {useState} from "react";
import { Row, Col } from 'antd'
import HeaderComponent from '../../components/header';
import FooterComponent from "../../components/footer";
import {InfraIcon, SmartIcon, SmartMeterIcon, StructIcon, MetroIcon} from '../../assets/images/fixed_net'
import {ArrowRight, BuldingImg} from '../../assets/images'
import './style.css'
const ManagePage = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const toggleMenu = () => {
        console.log("menuVisible", menuVisible);
        setMenuVisible(!menuVisible);
    };
    return(
        <>
            <HeaderComponent flag={false} toggleMenu={toggleMenu} menuVisible={menuVisible} /> 
            <div className="main_head_mange">
                <Row>
                    <Col span={4} />
                    <Col span={11}>
                        <h1 className="fix_head_txt"> Managed <span className="head_cay_txt"> Networks </span> </h1>
                        <span className="head_sub_txt"> 
                            Elevate your business with our seamless, market-leading solutions—from project management to cutting-edge technology. Trust in our commitment to excellence for sustained success.
                        </span>
                    </Col>
                </Row>
                <Row className="brd_row">
                    <Col span={4} />
                    <Col span={11}>
                        <span className="head_brd_txt"> 
                           Home / Managed Services
                        </span>
                    </Col>
                </Row>
            </div>
            <div>
                <Row className="center_div" gutter={[10,10]}>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Fleet Management </h1>
                        <span>
                            Management of Clients Fleet
                            Drivers and User Training
                            Scheduling of Vehicles
                            Fuel Management
                            Accident and Maintenance Management
                            Log Book and Cost Control Management
                            Fines Management
                            Providing Turn key Solutions 
                        </span>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <img src={InfraIcon} alt='icon' className="net_img" />
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <img src={StructIcon} alt='icon' className="net_img"  />
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Structured Cabling </h1>
                        <span> 
                            Design, Planning , Installation and Maintenance of Civil Works, Cable Laying and Associated works for deploying Long Haul, FTTH/FTTM/FTTB/FTTX, Inside Plant (ISP) and Outside Plant (OSP)
                            . Upgrading and Management of FDHs (LMT)
                            . OLT installation and Maintenance works.
                        </span>
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Smart Solutions </h1>
                        <span> 
                        We install diverse smart devices seamlessly, enabling efficient home automation. Our expertise covers installation and seamless management, ensuring smooth operation for remote control. Users access and control homes via phones, computers, and cutting-edge virtual assistants, reflecting our commitment to pioneering technology.
                        </span>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <img src={SmartIcon} alt='icon' className="net_img"  />
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <img src={SmartMeterIcon} alt='icon' className="net_img"  />
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Smart Meters Management </h1>
                        <span> 
                            Establishing Communication with Smart Meters (Water) through wired MBUS and Wireless with the MUC.
                            Installation of Infrastructure & Meters (LV Room to Meters Cabinets), terminations & Validation within the MUC Configuration
                        </span>
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Metro & Rail Networks Solutions </h1>
                        <span> 
                            Civil Work & Cable Lying
                            Cable Tray Installation
                            Wifi Solutions at Stations
                            IT & CCTV Camera Installation
                        </span>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <img src={MetroIcon} alt='icon' className="net_img"  />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <h1 className="net_main_txt txt_center"> Access Network </h1>
                        <div className="ass_net_div">
                            <p> 
                                <img src={ArrowRight}  alt='icon' />
                                Installation and maintenance of Access Network Active and Passive equipment in exchanges / remote sites and customer premises
                            </p>
                            <p> 
                                <img src={ArrowRight}  alt='icon' />
                                Fault Restoration & periodic preventive and corrective maintenance, mobile link provisioning and migration and network optimization
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <img src={BuldingImg}  alt="iccc" className="width_100"/>
                    </Col>
                </Row>
            </div>
            <FooterComponent normal={true} />
        </>
    )
}

export default ManagePage