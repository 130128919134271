import React, {useState} from "react";
import { Row, Col } from 'antd'
import HeaderComponent from '../../components/header'
import {InfraIcon, SmartIcon, SmartMeterIcon, StructIcon, MetroIcon} from '../../assets/images/fixed_net'
import {SecAssImg, SecCamImg} from '../../assets/images/security'
import {ArrowRight, BuldingImg} from '../../assets/images'
import FooterComponent from "../../components/footer";
import './style.css'
const SecurityPage = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const toggleMenu = () => {
        console.log("menuVisible", menuVisible);
        setMenuVisible(!menuVisible);
    };
    return(
        <>
            <HeaderComponent flag={false} toggleMenu={toggleMenu} menuVisible={menuVisible}  /> 
            <div className="main_head_sec">
                <Row>
                    <Col span={4} />
                    <Col span={11}>
                        <h1 className="fix_head_txt"> Security <span className="head_cay_txt"> Solutions </span> </h1>
                        <span className="head_sub_txt"> 
                        "Safeguard your assets with our comprehensive network & cybersecurity solutions. Rely on our expertise to ensure robust protection in an ever-evolving digital landscape.
                        </span>
                    </Col>
                </Row>
                <Row className="brd_row">
                    <Col span={4} />
                    <Col span={11}>
                        <span className="head_brd_txt"> 
                           Home / Security Solutions
                        </span>
                    </Col>
                </Row>
            </div>
            <div>
                <Row className="center_div" gutter={[10,10]}>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Security Solutions </h1>
                        <span> 
                            <p>
                                <img src={ArrowRight}  alt='icon' className="arrow_icon" />
                                HD Security Camera Systems
                            </p>
                            <p>
                                <img src={ArrowRight}  alt='icon' className="arrow_icon" />
                                Wireless Cameras
                            </p>
                            <ul>
                                <li> Audio, Video and Security Monitor </li>
                                <li> Action Sports Cameras </li>
                                <li> IP Based Security Solutions, CCTV Installations & Maintenance </li> 
                                <li> Access Control Systems, Intercom & Motion Sensors Light </li>
                                <li> Aerial Satellites Solutions </li>
                            </ul>
                        </span>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <img src={SecCamImg} alt='icon' className="net_img" />
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <img src={SecAssImg} alt='icon' className="net_img"  />
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Access Control Systems </h1>
                        <span> 
                            <p>
                                <img src={ArrowRight}  alt='icon' className="arrow_icon" />
                                Access control Systems
                            </p>
                            <p>
                                <img src={ArrowRight}  alt='icon' className="arrow_icon" />
                                Finger print access controlsystem
                            </p>
                            <ul>
                                <li> Face recognition access controlsystems </li>
                                <li> iris recognition systems, etc </li>
                                <li> Vehicles Monitoring System </li> 
                            </ul>
                        </span>
                    </Col>
                </Row>
            </div>
            <FooterComponent normal={true}  />
        </>
    )
}

export default SecurityPage