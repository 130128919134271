// NokiaIcon
import React, { useState } from "react";
import { Row, Col, Form, Input, Card } from "antd";
import { StarW, StarY, DubaiIcon, NavIcon, DirecIcon, SarjahIcon, AbuDubaiIcon, UkIcon, MapIcon } from "../../assets/images";
import HeaderComponent from "../../components/header";
import FooterComponent from "../../components/footer";
import "./style.css";
const {TextArea} = Input
const ContactUsPage = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const toggleMenu = () => {
    console.log("menuVisible", menuVisible);
    setMenuVisible(!menuVisible);
  };
  return (
    <>
      <HeaderComponent
        flag={false}
        toggleMenu={toggleMenu}
        menuVisible={menuVisible}
      />
      <div className="main_head_contact">
        <Row>
          <Col span={4} />
          <Col span={11}>
            <h1 className="fix_head_txt">
            Contact <p className="head_cay_txt"> Us </p>
            </h1>
            <span className="head_sub_txt">
                Feel free to get in touch with us at any time. our dedicated team is here and eager to assist you.
            </span>
          </Col>
        </Row>
        <Row className="brd_row">
          <Col span={4} />
          <Col span={11}>
            <span className="head_brd_txt">Home / Contact Us  </span>
          </Col>
        </Row>
      </div>
      <div className="gry_bg">
        <Row className="cent_div">
          <Col span={24}>
            <h1 className="top_clnt_txt"> Drop Us a Message </h1>
            <span className="top_sub_txt">
            Reach out and connect with us by dropping a message
            </span>
          </Col>
        </Row>
        <Row className="cent_div">
            <Col span={24}>
                <Form>
                    <Row gutter={[10,10]}>
                        <Col span={12}>
                            <Form.Item>
                                <Input placeholder="First Name" />
                            </Form.Item>
                            <Form.Item>
                                <Input placeholder="Mobile Number" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item>
                                <Input placeholder="Email" />
                            </Form.Item>
                            <Form.Item>
                                <Input placeholder="Subject" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item>
                                <TextArea placeholder="Message" />
                                {/* <Input placeholder="First Name" /> */}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
      </div>
      <div>
        <Row>
          <Col span={24} className="center_div">
            <Row gutter={[10, 10]}>
              <Col span={12}>
                <h2> Offices Location </h2>
                <span> Our Offices Locations World Wide </span>
                <Card className="loc_div">
                  <Row gutter={[8, 8]}>
                    <Col span={5} className="img">
                      <img src={DubaiIcon} alt="dubai_icon" />
                    </Col>
                    <Col span={14} className="img1">
                      <h2> Xad Technologies LLC Dubai Office </h2>
                      <span>
                        Opal Tower - Office 1308 - Dubai - United Arab Emirates
                      </span>
                      <div>
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarW} alt="sss" />
                      </div>
                    </Col>
                    <Col span={5} className="nav_div">
                      <img src={NavIcon} alt="na " />
                      <img src={DirecIcon} alt="na " className="dir" />
                    </Col>
                  </Row>
                </Card>
                <Card className="loc_div">
                  <Row gutter={[8, 8]}>
                    <Col span={5} className="img">
                      <img src={SarjahIcon} alt="dubai_icon" />
                    </Col>
                    <Col span={14} className="img1">
                      <h2> Xad Technologies LLC Sharjah Office </h2>
                      <span>
                        Mohammed Al Mulla Tower - Al Ittihad St - Al Nahda -
                        Sharjah - United Arab Emirates
                      </span>
                      <div>
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarW} alt="sss" />
                      </div>
                    </Col>
                    <Col span={5} className="nav_div">
                      <img src={NavIcon} alt="na " />
                      <img src={DirecIcon} alt="na " className="dir" />
                    </Col>
                  </Row>
                </Card>
                <Card className="loc_div">
                  <Row gutter={[8, 8]}>
                    <Col span={5} className="img">
                      <img src={AbuDubaiIcon} alt="dubai_icon" />
                    </Col>
                    <Col span={14} className="img1">
                      <h2> Xad Technologies LLC Abu Dhabi Office </h2>
                      <span> 9FFX+CCG - Musaffah - M-14 - Abu Dhabi </span>
                      <div>
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarW} alt="sss" />
                      </div>
                    </Col>
                    <Col span={5} className="nav_div">
                      <img src={NavIcon} alt="na " />
                      <img src={DirecIcon} alt="na " className="dir" />
                    </Col>
                  </Row>
                </Card>
                <Card className="loc_div">
                  <Row gutter={[8, 8]}>
                    <Col span={5} className="img">
                      <img src={UkIcon} alt="dubai_icon" />
                    </Col>
                    <Col span={14} className="img1">
                      <h2> Xad Technologies LLC UK Office </h2>
                      <span> 03 Park Lane Wembley, HA9 7RH</span>
                      <div>
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarY} alt="sss" />
                        <img src={StarW} alt="sss" />
                      </div>
                    </Col>
                    <Col span={5} className="nav_div">
                      <img src={NavIcon} alt="na " />
                      <img src={DirecIcon} alt="na " className="dir" />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={12}>
                <img src={MapIcon} alt="map icon" />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <FooterComponent normal={true} />
    </>
  );
};

export default ContactUsPage;