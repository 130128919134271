// NokiaIcon
import React, { useState } from "react";
import { Row, Col } from "antd";
import HeaderComponent from "../../components/header";
import FooterComponent from "../../components/footer";
import {
  GreyTickIcon,
  AlstomIcon,
  WhiteArrowIcon,
  Arrow,
} from "../../assets/images/our_projects";
import "./style.css";
const AlstomPage = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const toggleMenu = () => {
    console.log("menuVisible", menuVisible);
    setMenuVisible(!menuVisible);
  };
  return (
    <>
      <HeaderComponent
        flag={false}
        toggleMenu={toggleMenu}
        menuVisible={menuVisible}
      />
      <div className="main_head_alstom">
        <Row>
          <Col span={4} />
          <Col span={11}>
            <h1 className="fix_head_txt">
              Project <p className="head_cay_txt"> Alstom </p>
            </h1>
            <span className="head_sub_txt">
            Alstom's project focused on revolutionizing transportation infrastructure through innovation and sustainability, aiming for industry-wide progress.
            </span>
          </Col>
        </Row>
        <Row className="brd_row">
          <Col span={4} />
          <Col span={11}>
            <span className="head_brd_txt">Home / Project Alstom</span>
          </Col>
        </Row>
      </div>
      <div className="main_crr_div">
        <Row>
          <Col span={4} className="icon_div">
            <img src={AlstomIcon} alt="icon" />
          </Col>
          <Col span={20}>
            <h1 className="net_main_txt"> XAD Projects with Alstom </h1>
            <p className="net_sub_txt">
                Here are some of our top projects with Alstom
            </p>
          </Col>
        </Row>
      </div>
      <div className="cent_div_n">
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt"> PSD the Route 2020 Metro Project </p>
          </Col>
          <Col span={2} className="white_arr_div">
            <img src={WhiteArrowIcon} alt="tcic" />
          </Col>
        </Row>
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt"> DU - Cable trays installation METRO 2020 </p>
          </Col>
          <Col span={2} className="white_arr_div">
            <img src={WhiteArrowIcon} alt="tcic"  />
          </Col>
        </Row>
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt">Dubai Metro 2020 - SIG - ANPR </p>
          </Col>
          <Col span={2} className="white_arr_div">
            <img src={WhiteArrowIcon} alt="tcic"  />
          </Col>
        </Row>
      </div>
      <FooterComponent normal={true} />
    </>
  );
};

export default AlstomPage;