import React, {useState} from "react";
import { Row, Col } from 'antd'
import HeaderComponent from '../../components/header'
import FooterComponent from '../../components/footer'
import {Inno1, Inno2} from '../../assets/images/innomix'
import {Dc1, Dc2, Dc3, Dc4, Dc5, Dc6} from '../../assets/images/dc'
import {ArrowRight} from '../../assets/images'
import './style.css'
const DcPage = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const toggleMenu = () => {
        console.log("menuVisible", menuVisible);
        setMenuVisible(!menuVisible);
    };
    return(
        <>
            <HeaderComponent flag={false} toggleMenu={toggleMenu} menuVisible={menuVisible} /> 
            <div className="main_head_dc">
                <Row>
                    <Col span={4} />
                    <Col span={11}>
                        <h1 className="fix_head_txt"> DC Pro <br/> Engineering <span className="head_cay_txt"> LLC </span> </h1>
                        <span className="head_sub_txt"> 
                            DC PRO Engineering: Global experts in District Cooling and Green Buildings MEP Design, offering optimized, energy-efficient solutions for diverse energy sources and water systems 
                        </span>
                    </Col>
                </Row>
                <Row className="brd_row">
                    <Col span={4} />
                    <Col span={11}>
                        <span className="head_brd_txt"> 
                           Home / DC Pro Engineering LLC
                        </span>
                    </Col>
                </Row>
            </div>
            <div>
                <Row className="center_div" gutter={[10,10]}>
                    <Col span={24}>
                        <h1 className="net_main_txt"> District Cooling Engineering </h1>
                        <p className="net_sub_txt" > Specializing in Efficient Cooling Solutions for Communities. </p>
                        <p className="net_sub_txt">
                            C PRO Engineering is a recognized leading world authority in the field of District Cooling engineering and Green Buildings MEP Design. Whether you are using electric power, natural gas or diesel fuel as an energy source or whether you are using fresh water, sea water or treated sewage effluent water for heat rejection we can propose an optimized energy efficient solution for you
                        </p>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Main Projects </h1>
                        <span> 
                            <p>
                                <img src={ArrowRight}  alt='icon' className="icon_width"  />
                                Financial and Feasibility Studies.
                            </p>
                            <p>
                                <img src={ArrowRight}  alt='icon' className="icon_width"  />
                                District Cooling Service Agreement
                            </p>
                            <ul>
                                <li> Engineering Design </li>
                                <li> Construction Management </li>
                                <li> Corrective action implementation. </li>
                                <li> Green Building Services Energy. </li>
                                <li> Day Lighting and Lighting Systems and Designs </li>
                            </ul>
                        </span>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <img src={Dc1} alt='icon' className="net_img" />
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <img src={Dc2} alt='icon' className="net_img"  />
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Motor City – EMICOOL </h1>
                        <span> 
                            <p> Scope of DC PRO Engineering: </p>
                            <p> 
                                Design for Motor City Project District Cooling Plants 1&2 of total capacity 92,000 TR, chilled water underground piping network consisting of 50 Km CS & HDPE piping network. 23 Energy Transfer Stations of total capacity 40,000 TR
                                Construction management & Supervision for Motor City District Cooling Plants 1 & 2 of total capacity 92,000 TR, 23 Energy Transfer Stations and chilled water underground piping network consisting of 50 Km CS & HDPE piping network.
                            </p>
                        </span>
                    </Col>


                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Dubai Festival City </h1>
                        <span> 
                            <p> Scope of DC PRO Engineering: </p>
                            <p> 
                                Engineering design, Construction Management and Supervision for Building Material City District Cooling Plant of total capacity 30,000 TR, Energy Transfer Stations and chilled water distribution network consisting of 6 Km CS piping network
                            </p>
                        </span>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <img src={Dc3} alt='icon' className="net_img"  />
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <img src={Dc4} alt='icon' className="net_img"  />
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> West Bay District Cooling Scheme – BEMCO </h1>
                        <span> 
                            <p> Scope of DC PRO Engineering: </p>
                            <p> 
                                Engineering design for West Bay Qatar Cool District Cooling Plant 2 of capacity 37,000
                            </p>
                        </span>
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Dubai Investment Park – EMICOOL </h1>
                        <span> 
                            <p> Scope of DC PRO Engineering: </p>
                            <p> 
                             Engineering design for Dubai Investment Park District Cooling Plant #1 Expansion & Modification of total capacity 15,000 TRDesign for Dubai Investment Part District Cooling Plant # 3 of total capacity 75,000 TR
                            </p>
                        </span>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <img src={Dc5} alt='icon' className="net_img"  />
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <img src={Dc6} alt='icon' className="net_img"  />
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Dubai Pearl </h1>
                        <span> 
                            <p> Scope of DC PRO Engineering: </p>
                            <p> 
                                Engineering review for Dubai Pearl District Cooling plant project
                            </p>
                        </span>
                    </Col>

                </Row>
            </div>
            <FooterComponent normal={true}  />
        </>
    )
}

export default DcPage