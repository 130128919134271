import React, { useState } from "react";
import { Row, Col } from "antd";
import HeaderComponent from "../../components/header";
import FooterComponent from "../../components/footer";
import {
  //   EtisalatIcon,
  Duicon,
  GreyTickIcon,
  WhiteArrowIcon,
} from "../../assets/images/our_projects";
import "./style.css";
const DuPage = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const toggleMenu = () => {
    console.log("menuVisible", menuVisible);
    setMenuVisible(!menuVisible);
  };
  return (
    <>
      <HeaderComponent
        flag={false}
        toggleMenu={toggleMenu}
        menuVisible={menuVisible}
      />
      <div className="main_head_du">
        <Row>
          <Col span={4} />
          <Col span={11}>
            <h1 className="fix_head_txt">
              Project <p className="head_cay_txt"> Du </p>
            </h1>
            <span className="head_sub_txt">
              DU, a UAE telecom giant, pioneered a transformative project using
              5G and eco-friendly approaches. Collaborating for innovation, they
              aimed to bridge digital gaps and drive societal growth locally and
              regionally. customers.
            </span>
          </Col>
        </Row>
        <Row className="brd_row">
          <Col span={4} />
          <Col span={11}>
            <span className="head_brd_txt">Home / Project Etisalat</span>
          </Col>
        </Row>
      </div>
      <div className="main_crr_div">
        <Row>
          <Col span={4} className="icon_div">
            <img src={Duicon} alt="icon" />
          </Col>
          <Col span={20}>
            <h1 className="net_main_txt"> XAD Projects with Etisalat </h1>
            <p className="net_sub_txt">
              Here are some of our top projects with Etisalat
            </p>
          </Col>

          <Col span={24}>
            <div className="txt_div">
              <h1 className="net_main_txt">
                Emirates Integrated Telecommunication Services
              </h1>
              <p className="net_sub_txt">
                Xad has completed several important projects with du
                successfully and served customers.
              </p>
            </div>
          </Col>
        </Row>
      </div>
      <div className="cent_div">
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt"> Migration Project </p>
          </Col>
          <Col span={2}>
            <img src={WhiteArrowIcon} alt="tcic" className="icons" />
          </Col>
        </Row>
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt"> Fixed National Launch </p>
          </Col>
          <Col span={2}>
            <img src={WhiteArrowIcon} alt="tcic" className="icons" />
          </Col>
        </Row>
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt">Fixed Services - Fulfillment & Assurance</p>
          </Col>
          <Col span={2}>
            <img src={WhiteArrowIcon} alt="tcic" className="icons" />
          </Col>
        </Row>
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt"> du Body Shop </p>
          </Col>
          <Col span={2}>
            <img src={WhiteArrowIcon} alt="tcic" className="icons" />
          </Col>
        </Row>
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt"> Du Manpower </p>
          </Col>
          <Col span={2}>
            <img src={WhiteArrowIcon} alt="tcic" className="icons" />
          </Col>
        </Row>
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt"> du TCS </p>
          </Col>
          <Col span={2}>
            <img src={WhiteArrowIcon} alt="tcic" className="icons" />
          </Col>
        </Row>
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt"> Wipro du</p>
          </Col>
          <Col span={2}>
            <img src={WhiteArrowIcon} alt="tcic" className="icons" />
          </Col>
        </Row>
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt">DU Bayanat Project</p>
          </Col>
          <Col span={2}>
            <img src={WhiteArrowIcon} alt="tcic" className="icons" />
          </Col>
        </Row>
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt">DU Migration of Al Quoz</p>
          </Col>
          <Col span={2}>
            <img src={WhiteArrowIcon} alt="tcic" className="icons" />
          </Col>
        </Row>
      </div>
      <FooterComponent normal={true} />
    </>
  );
};

export default DuPage;