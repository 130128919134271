import React, {useState} from "react";
import { Row, Col } from 'antd'
import HeaderComponent from '../../components/header'
import {InfraIcon, SmartIcon, SmartMeterIcon, StructIcon, MetroIcon} from '../../assets/images/fixed_net'
import {ArrowRight, BuldingImg} from '../../assets/images'
import FooterComponent from "../../components/footer";
import './style.css'
const FixedNetPage = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const toggleMenu = () => {
        console.log("menuVisible", menuVisible);
        setMenuVisible(!menuVisible);
    };
    return(
        <>
            <HeaderComponent flag={false} toggleMenu={toggleMenu} menuVisible={menuVisible}  /> 
            <div className="main_head">
                <Row>
                    <Col span={4} />
                    <Col span={11}>
                        <h1 className="fix_head_txt"> Fixed <span className="head_cay_txt"> Networks </span> </h1>
                        <span className="head_sub_txt"> 
                            Discover the strength of our Fixed Networks: Reliable infrastructure, seamless connectivity. Your stable foundation in a dynamic digital landscape.
                        </span>
                    </Col>
                </Row>
                <Row className="brd_row">
                    <Col span={4} />
                    <Col span={11}>
                        <span className="head_brd_txt"> 
                           Home / Fixed Network
                        </span>
                    </Col>
                </Row>
            </div>
            <div>
                <Row className="center_div" gutter={[10,10]}>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Infrastructure & Network Development </h1>
                        <span> IT Infrastructure, CCTV, Fire Alarm Systems, PABX and Smart Solution Structured cables & Equipment Installations over Copper and Fiber Networks. Migration of Copper to Fiber Networks </span>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <img src={InfraIcon} alt='icon' className="net_img" />
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <img src={StructIcon} alt='icon' className="net_img"  />
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Structured Cabling </h1>
                        <span> 
                            Design, Planning , Installation and Maintenance of Civil Works, Cable Laying and Associated works for deploying Long Haul, FTTH/FTTM/FTTB/FTTX, Inside Plant (ISP) and Outside Plant (OSP)
                            . Upgrading and Management of FDHs (LMT)
                            . OLT installation and Maintenance works.
                        </span>
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Smart Solutions </h1>
                        <span> 
                        We install diverse smart devices seamlessly, enabling efficient home automation. Our expertise covers installation and seamless management, ensuring smooth operation for remote control. Users access and control homes via phones, computers, and cutting-edge virtual assistants, reflecting our commitment to pioneering technology.
                        </span>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <img src={SmartIcon} alt='icon' className="net_img"  />
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <img src={SmartMeterIcon} alt='icon' className="net_img"  />
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Smart Meters Management </h1>
                        <span> 
                            Establishing Communication with Smart Meters (Water) through wired MBUS and Wireless with the MUC.
                            Installation of Infrastructure & Meters (LV Room to Meters Cabinets), terminations & Validation within the MUC Configuration
                        </span>
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Metro & Rail Networks Solutions </h1>
                        <span> 
                            Civil Work & Cable Lying
                            Cable Tray Installation
                            Wifi Solutions at Stations
                            IT & CCTV Camera Installation
                        </span>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <img src={MetroIcon} alt='icon' className="net_img"  />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <h1 className="net_main_txt txt_center"> Access Network </h1>
                        <div className="ass_net_div">
                            <p> 
                                <img src={ArrowRight}  alt='icon' />
                                Installation and maintenance of Access Network Active and Passive equipment in exchanges / remote sites and customer premises
                            </p>
                            <p> 
                                <img src={ArrowRight}  alt='icon' />
                                Fault Restoration & periodic preventive and corrective maintenance, mobile link provisioning and migration and network optimization
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <img src={BuldingImg}  alt="iccc" className="width_100"/>
                    </Col>
                </Row>
            </div>
            <FooterComponent normal={true}  />
        </>
    )
}

export default FixedNetPage