// NokiaIcon
import React, { useState } from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import HeaderComponent from "../../components/header";
import FooterComponent from "../../components/footer";
import {
    Etlisalat,
    Alstom,
    Du,
    Ericsson,
    Huawei,
    Nokia,
    Solar,
    Main,
  } from "../../assets/images/projects";
import "./style.css";
const OurProjectsPage = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const toggleMenu = () => {
    console.log("menuVisible", menuVisible);
    setMenuVisible(!menuVisible);
  };
  return (
    <>
      <HeaderComponent
        flag={false}
        toggleMenu={toggleMenu}
        menuVisible={menuVisible}
      />
      <div className="main_head_projects">
        <Row>
          <Col span={4} />
          <Col span={11}>
            <h1 className="fix_head_txt">
            Our <p className="head_cay_txt"> Projects </p>
            </h1>
            <span className="head_sub_txt">
                We specialize in servicing and managing projects for industry giants like Nokia, DU, Alstom, Huawei, Etisalat, and Ericsson, encompassing a broad spectrum from telecommunications to solar initiatives.
            </span>
          </Col>
        </Row>
        <Row className="brd_row">
          <Col span={4} />
          <Col span={11}>
            <span className="head_brd_txt">Home / Our Projects</span>
          </Col>
        </Row>
      </div>
      <div className="main_crr_div">
        <Row>
            <Col span={24} align="middle" className="text-center">
                <h1 className="pro_main_txt"> Our Projects </h1>
                <span className="pro_sub_txt">
                Explore the diverse portfolio of Our Projects, where innovation
                meets execution.
                </span>
            </Col>
            <Col span={24}>
                <Row className="center_div cont_centr mt_5" gutter={[20, 20]}>
                    <Col span={4} className="txt_center">
                        <Link to="/est_proj">
                            <img src={Etlisalat} alt="p1" className="width_170" />
                            <h2 className="pro_blk_txt"> Etisalat </h2>
                            <span className="pro_gry_txt">
                                A leading telecom company providing connectivity solutions
                                worldwide.
                            </span>
                        </Link>
                    </Col>
                    <Col span={4} className="txt_center">
                        <Link to="/du_proj">
                            <img src={Du} alt="p1" className="width_170" />
                            <h2 className="pro_blk_txt"> DU Project </h2>
                            <span className="pro_gry_txt">
                            UAE's pioneering telecom delivering seamless connectivity.
                            </span>
                        </Link>
                    </Col>
                    <Col span={4} className="txt_center">
                        <Link to="/nokia_proj">
                            <img src={Nokia} alt="p1" className="width_170" />
                            <h2 className="pro_blk_txt"> Nokia </h2>
                            <span className="pro_gry_txt">
                            A global leader in technology, shaping the future with
                            connectivity solutions.
                            </span>
                        </Link>
                    </Col>
                    <Col span={4} className="txt_center">
                        <Link to="/huawei_proj">
                            <img src={Huawei} alt="p1" className="width_170" />
                            <h2 className="pro_blk_txt"> Huawei </h2>
                            <span className="pro_gry_txt">
                            Driving technological innovation with global connectivity
                            solutions.
                            </span>
                        </Link>
                    </Col>
                    <Col span={4} className="txt_center">
                        <Link to="/alstom_proj">
                            <img src={Alstom} alt="p1" className="width_170" />
                            <h2 className="pro_blk_txt"> Alstom </h2>
                            <span className="pro_gry_txt">
                            Powering transportation with cutting-edge rail and mobility
                            solutions.
                            </span>
                        </Link>
                    </Col>
                    </Row>
                <Row className="center_div cont_centr mt_5" gutter={[20, 20]}>
                    <Col span={4} className="txt_center">
                        <Link to="/ericsson_proj">
                            <img src={Ericsson} alt="p1" className="width_170" />
                            <h2 className="pro_blk_txt"> Ericsson </h2>
                            <span className="pro_gry_txt">
                            Pioneering global connectivity through innovative telecom
                            solutions.
                            </span>
                        </Link>
                    </Col>
                    <Col span={12}>
                        <img src={Main} alt="ma " className="width_100" />
                    </Col>
                    <Col span={4} className="txt_center">
                        <Link to="/solar_proj">
                            <img src={Solar} alt="p1" className="width_170" />
                            <h2 className="pro_blk_txt"> Solar Projects </h2>
                            <span className="pro_gry_txt">
                            Empowering a sustainable future through innovative solar
                            energy solutions
                            </span>
                        </Link>
                    </Col>
                </Row>
            </Col>
        </Row>
      </div>
      <FooterComponent normal={true} />
    </>
  );
};

export default OurProjectsPage;