import React, {useState} from "react";
import { Row, Col } from 'antd'
import HeaderComponent from '../../components/header'
import FooterComponent from '../../components/footer'
import {ArrowRight, WatanSlider, SliderArrLeft, SliderArrRight} from '../../assets/images'
import './style.css'
const WatanPage = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const toggleMenu = () => {
        console.log("menuVisible", menuVisible);
        setMenuVisible(!menuVisible);
    };
    return(
        <>
            <HeaderComponent flag={false} toggleMenu={toggleMenu} menuVisible={menuVisible} /> 
            <div className="main_head_watan">
                <Row>
                    <Col span={4} />
                    <Col span={11}>
                        <h1 className="fix_head_txt"> Watan <br/> Properties <span className="head_cay_txt"> LLC </span> </h1>
                        <span className="head_sub_txt"> 
                            Watan excels in real estate development, management, and promotion, crafting diverse value-added projects in the UAE and Lebanon while representing individual investors and international collaborators.
                        </span>
                    </Col>
                </Row>
                <Row className="brd_row">
                    <Col span={4} />
                    <Col span={11}>
                        <span className="head_brd_txt"> 
                           Home / Watan Properties LLC
                        </span>
                    </Col>
                </Row>
            </div>
            <div>
                <Row className="center_div" gutter={[10,10]}>
                    <Col span={24}>
                        <h1 className="net_main_txt"> Property Development </h1>
                        <p className="net_sub_txt"> 
                            Specialist in Real Estate Development, Promotion and Management Introduction
                        </p>
                        <p className="net_sub_txt">
                            Watan is specialized in providing Real Estate Development, Promotion and Management solutions. Watan provides Real Estate Management services to many Landlords. Watan also presents and look after interests of many individual investors / businessmen and international collaborators in Real Estate Projects Watan has developed value added Real Estate projects, which includes from villa to multi story towers in the UAE and Lebanon.
                        </p>
                    </Col>
                    <Col span={12}>
                        <h1 className="net_main_txt"> Products & Specialities: </h1>
                        <p>
                            <img src={ArrowRight}  alt='icon' className="icon_width"  />
                            Dubai Water Front
                        </p>
                        <p>
                            <img src={ArrowRight}  alt='icon' className="icon_width"  />
                            Dubai World
                        </p>
                        <p>
                            <img src={ArrowRight}  alt='icon' className="icon_width"  />
                            RAK Islands,
                        </p>
                        <p>
                            <img src={ArrowRight}  alt='icon' className="icon_width"  />
                            Zohara Ajman,
                        </p>
                        <p>
                            <img src={ArrowRight}  alt='icon' className="icon_width"  />
                            Jebel Ali Dubai,
                        </p>
                        <p>
                            <img src={ArrowRight}  alt='icon' className="icon_width"  />
                            Nahda Sharjah
                        </p>
                        <p>
                            <img src={ArrowRight}  alt='icon' className="icon_width"  />
                            Jumeirah Village
                        </p>
                        <p>
                            <img src={ArrowRight}  alt='icon' className="icon_width"  />
                            Down Town Jebel Ali
                        </p>
                        <p>
                            <img src={ArrowRight}  alt='icon' className="icon_width"  />
                            International City Dubai
                        </p>
                        <p>
                            <img src={ArrowRight}  alt='icon' className="icon_width"  />
                            Jebel Ali Industrial Area
                        </p>
                        <p>
                            <img src={ArrowRight}  alt='icon' className="icon_width"  />
                            Lebanon
                        </p>
                        <p>
                            <img src={ArrowRight}  alt='icon' className="icon_width"  />
                            Jordan
                        </p>
                        <p>
                            <img src={ArrowRight}  alt='icon' className="icon_width"  />
                            Oman
                        </p>
                    </Col>
                    <Col span={12}>
                        <Row gutter={[10,10]}>
                            <Col span={2} className="slider_icon_div"> <img src={SliderArrLeft} alt="slider" /> </Col>
                            <Col span={20}> <img src={WatanSlider} alt="slider" className="width_100" /> </Col>
                            <Col span={2} className="slider_icon_div"> <img src={SliderArrRight} alt="slider"/> </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <FooterComponent normal={true}  />
        </>
    )
}

export default WatanPage