import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
const LottieAnimation = (props) => {
    const containerRef = useRef(null);
  
    useEffect(() => {
      const anim = lottie.loadAnimation({
        container: containerRef.current,
        animationData: props.animationData,
        renderer: 'svg', // Choose the renderer based on your preference
        loop: true,
        autoplay: true,
      });
  
      return () => anim.destroy(); // Cleanup animation on unmount
    }, []);
  
    return <div className={props.className} ref={containerRef} />;
  };
  
  export default LottieAnimation;