import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomePage from "../pages/home";
import HomeUsaPage from "../pages/home/usa";
import HomeUkPage from "../pages/home/uk";
import FixedNetPage from "../pages/fixedPage";
import MobielNetPage from "../pages/mobNet";
import ManagePage from "../pages/mangePage";
import HrPage from "../pages/hrPage";
import SecurityPage from "../pages/securityPage";
import IctPage from "../pages/ictPage";
import EnergyPage from "../pages/energyPage";
import CareerPage from "../pages/careerPage";
import { EtisalatPage, DuPage, NokiaPage, EricssonPage, HuaweiPage, AlstomPage, SolarPage, OurProjectsPage } from "../pages/projects";
import ClientPage from "../pages/clientPage";
import OrganStrucPage from "../pages/organStuctPage";
import WeArePage from "../pages/weArePage";
import ManagementPage from "../pages/managePage";
import LicensesPage from "../pages/licensesPage";
import GalleryPage from "../pages/galleryPage";
import ContactUsPage from "../pages/contactUsPage";
import TorusPage from "../pages/torusPage";
import WatanPage from "../pages/watanPage";
import InnomixPage from "../pages/innomaxPage";
import DcPage from "../pages/dcPage";
const RoutePage = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={HomeUsaPage} />
          <Route exact path="/ksa" component={HomePage} />
          <Route exact path="/uk" component={HomeUkPage} />
          <Route exact path="/fixed_net" component={FixedNetPage} />
          <Route exact path="/mobile_net" component={MobielNetPage} />
          <Route exact path="/manage" component={ManagePage} />
          <Route exact path="/hr_out" component={HrPage} />
          <Route exact path="/security" component={SecurityPage} />
          <Route exact path="/ict" component={IctPage} />
          <Route exact path="/energy" component={EnergyPage} />
          <Route exact path="/career" component={CareerPage} />
          <Route exact path="/est_proj" component={EtisalatPage} />
          <Route exact path="/du_proj" component={DuPage} />
          <Route exact path="/nokia_proj" component={NokiaPage} />
          <Route exact path="/ericsson_proj" component={EricssonPage} />
          <Route exact path="/huawei_proj" component={HuaweiPage} />
          <Route exact path="/alstom_proj" component={AlstomPage} />
          <Route exact path="/solar_proj" component={SolarPage} />
          <Route exact path="/our_proj" component={OurProjectsPage} />
          <Route exact path="/clients" component={ClientPage} />
          <Route exact path="/organ_struct" component={OrganStrucPage} />
          <Route exact path="/we_are" component={WeArePage} />
          <Route exact path="/management" component={ManagementPage} />
          <Route exact path="/licenses" component={LicensesPage} />
          <Route exact path="/gallery" component={GalleryPage} />
          <Route exact path="/contact_us" component={ContactUsPage} />
          <Route exact path="/torus" component={TorusPage} />
          <Route exact path="/watan" component={WatanPage} />
          <Route exact path="/innomix" component={InnomixPage} />
          <Route exact path="/dc" component={DcPage} />
        </Switch>
      </Router>
    </>
  );
};
export default RoutePage;
