// NokiaIcon
import React, { useState } from "react";
import { Row, Col } from "antd";
import HeaderComponent from "../../components/header";
import FooterComponent from "../../components/footer";
import {
  GreyTickIcon,
  NokiaIcon,
  WhiteArrowIcon,
  Arrow,
} from "../../assets/images/our_projects";
import "./style.css";
const NokiaPage = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const toggleMenu = () => {
    console.log("menuVisible", menuVisible);
    setMenuVisible(!menuVisible);
  };
  return (
    <>
      <HeaderComponent
        flag={false}
        toggleMenu={toggleMenu}
        menuVisible={menuVisible}
      />
      <div className="main_head_nokia">
        <Row>
          <Col span={4} />
          <Col span={11}>
            <h1 className="fix_head_txt">
              Project <p className="head_cay_txt"> Nokia </p>
            </h1>
            <span className="head_sub_txt">
              Nokia's project aimed to revolutionize telecom infrastructure
              through 5G and sustainability, fostering global connectivity and
              innovation through strategic partnerships.
            </span>
          </Col>
        </Row>
        <Row className="brd_row">
          <Col span={4} />
          <Col span={11}>
            <span className="head_brd_txt">Home / Project Etisalat</span>
          </Col>
        </Row>
      </div>
      <div className="main_crr_div">
        <Row>
          <Col span={4} className="icon_div">
            <img src={NokiaIcon} alt="icon" />
          </Col>
          <Col span={20}>
            <h1 className="net_main_txt"> XAD Projects with Nokia </h1>
            <p className="net_sub_txt">
              Here are some of our top projects with Nokia
            </p>
          </Col>

          <Col span={24}>
            <div className="txt_div">
              <h1 className="net_main_txt">
                Emirates Integrated Telecommunication ServicesXad has completed
                several important projects with du successfully and served
                customers.
              </h1>
            </div>
          </Col>
        </Row>
      </div>
      <div className="cent_div_n">
        <Row>
          <Col span={24}>
            <div>
              <img src={Arrow} alt="Nokia arrow" />
              <span className="arrow_txt">
                Installation & Upgrading of Microwave 1.8/ 1.2/ 0.6/ 0.3
              </span>
            </div>
            <div>
              <img src={Arrow} alt="Nokia arrow" />
              <span className="arrow_txt">
                BSS(Base Station System) / RAN(Radio Access Network) Rollout.
              </span>
            </div>
            <div>
              <img src={Arrow} alt="Nokia arrow" />
              <span className="arrow_txt">
                BSS / RAN Swap
              </span>
            </div>
            <div>
              <img src={Arrow} alt="Nokia arrow" />
              <span className="arrow_txt">
                BSS / RAN IBS(Indoor Building Solution).
              </span>
            </div>
            <div>
              <img src={Arrow} alt="Nokia arrow" />
              <span className="arrow_txt">
                BSS / RAN Expansion.
              </span>
            </div>
            <div>
              <img src={Arrow} alt="Nokia arrow" />
              <span className="arrow_txt">
                Transmission(PDH,SDH).
              </span>
            </div>
            <div>
              <img src={Arrow} alt="Nokia arrow" />
              <span className="arrow_txt">
                Core Implementation (MSC/BSC).
              </span>
            </div>
            <div>
              <img src={Arrow} alt="Nokia arrow" />
              <span className="arrow_txt">
                Telecom and installation of BTS & MW
              </span>
            </div>
            <div>
              <img src={Arrow} alt="Nokia arrow" />
              <span className="arrow_txt">
                2G/3G Nokia & Ericsson cabinets
              </span>
            </div>
            <div>
              <img src={Arrow} alt="Nokia arrow" />
              <span className="arrow_txt">
                Fault analyzing
              </span>
            </div>
            <div>
              <img src={Arrow} alt="Nokia arrow" />
              <span className="arrow_txt">
                Microwave transmission and Fiber ADM
              </span>
            </div>
            <div>
              <img src={Arrow} alt="Nokia arrow" />
              <span className="arrow_txt">
                Installation & commissioning of RBS 6000, 3000, 2000 and BTS 3900 equipment PDH/SDH commissioning
              </span>
            </div>
            <div>
              <img src={Arrow} alt="Nokia arrow" />
              <span className="arrow_txt">
                Design/ planning/deployment of IP/ Ethernet transport solution as used in mobile operator network
              </span>
            </div>
            <div>
              <img src={Arrow} alt="Nokia arrow" />
              <span className="arrow_txt">
                UMTS, LTE and handling large scale installation
              </span>
            </div>
          </Col>
        </Row>
        <br/ >
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt"> Nokia Du TI IBS </p>
          </Col>
          <Col span={2}>
            <img src={WhiteArrowIcon} alt="tcic" className="icons" />
          </Col>
        </Row>
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt"> Nokia Outdoor </p>
          </Col>
          <Col span={2}>
            <img src={WhiteArrowIcon} alt="tcic" className="icons" />
          </Col>
        </Row>
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt">Nokia Modernization</p>
          </Col>
          <Col span={2}>
            <img src={WhiteArrowIcon} alt="tcic" className="icons" />
          </Col>
        </Row>
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt"> Nokia GPON </p>
          </Col>
          <Col span={2}>
            <img src={WhiteArrowIcon} alt="tcic" className="icons" />
          </Col>
        </Row>
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt"> Nokia PK </p>
          </Col>
          <Col span={2}>
            <img src={WhiteArrowIcon} alt="tcic" className="icons" />
          </Col>
        </Row>
        <Row gutter={[10, 10]} className="gry_div">
          <Col span={2}>
            <img src={GreyTickIcon} alt="tcic" className="icons" />
          </Col>
          <Col span={20}>
            <p className="grry_txt"> Nokia PK </p>
          </Col>
          <Col span={2}>
            <img src={WhiteArrowIcon} alt="tcic" className="icons" />
          </Col>
        </Row>
      </div>
      <FooterComponent normal={true} />
    </>
  );
};

export default NokiaPage;