import React from "react";
import RoutePage from "./routes";
import "antd/dist/antd.css";
import './index.css'


function App() {
  return <RoutePage />
}

export default App;
