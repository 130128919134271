import React, {useState} from "react";
import { Row, Col } from 'antd'
import HeaderComponent from '../../components/header'
import FooterComponent from '../../components/footer'
import {Radio, Servey, Tower} from '../../assets/images/mobile_net'
import {ArrowRight} from '../../assets/images'
import './style.css'
const MobielNetPage = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const toggleMenu = () => {
        console.log("menuVisible", menuVisible);
        setMenuVisible(!menuVisible);
    };
    return(
        <>
            <HeaderComponent flag={false} toggleMenu={toggleMenu} menuVisible={menuVisible} /> 
            <div className="main_head_mob">
                <Row>
                    <Col span={4} />
                    <Col span={11}>
                        <h1 className="fix_head_txt"> Mobile <br/> <span className="head_cay_txt"> Networks </span> </h1>
                        <span className="head_sub_txt"> 
                            Empower your mobility with our cutting-edge Mobile Networks solutions.
                        </span>
                    </Col>
                </Row>
                <Row className="brd_row">
                    <Col span={4} />
                    <Col span={11}>
                        <span className="head_brd_txt"> 
                           Home / Mobile Network
                        </span>
                    </Col>
                </Row>
            </div>
            <div>
                <Row className="center_div" gutter={[10,10]}>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Microwave Installations </h1>
                        <span> 
                            <p>
                                <img src={ArrowRight}  alt='icon' className="icon_width"  />
                                Installation & Upgrading of Microwave Antennas, 3.0,1.8/ 1.2/ 0.6/ 0.3
                            </p>
                            <ul>
                                <li> 3 meters to 9 Meters Pole Installations </li>
                                <li> Installation, commission Alignment, configuration and testing of Microwave links, </li>
                                <li> Microwave transmission and Fiber ADM </li>
                                <li> Installation & commissioning of RBS 6000, 3000, 2000 and BTS 3900 equipment PDH/SDH commissioning. </li>
                            </ul>
                        </span>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <img src={Radio} alt='icon' className="net_img" />
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <img src={Servey} alt='icon' className="net_img"  />
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> IBS DAS & TI Solutions </h1>
                        <span> 
                            <p> 
                                <img src={ArrowRight}  alt='icon' className="icon_width" />
                                IBS/DAS & TI equipment Installation
                            </p>
                            <p>
                                <img src={ArrowRight}  alt='icon' className="icon_width" />
                                LOS & Site Survey, Planning & Designing
                            </p>
                            <ul>
                                <li> SAQ & Site Validation. </li>
                                <li> TE 2G/3G/LTE and WiMAX solutions in Multivendor Environment </li>
                                <li> Site PIM testing, DAS Antenna Testing by Site Master </li>
                                <li> Preventive operation and maintenance </li>
                                <li> PAT and FAT with the customer </li>
                            </ul>
                        </span>
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Radio Networks </h1>
                        <span> 
                            <p>
                                <img src={ArrowRight} alt="iccc" className="icon_width" />
                                Design, and optimization of radio networks including drive tests, collection of data, post processing of data and analysis
                            </p>
                            <ul>
                                <li> Corrective & Preventive Maintenance </li>
                                <li> Network Data Monitoring (Statistics / Alarms) </li> 
                                <li> Network Analysis & Network Adjustments: 
                                    <ol>
                                        <li> BSS(Base Station System) / RAN(Radio Access Network) Rollout. </li>
                                        <li> BSS / RAN Swap.  </li>
                                        <li> BSS / RAN IBS(Indoor Building Solution). </li>
                                        <li> BSS / RAN Expansion. </li>
                                    </ol>
                                    
                                </li>
                            </ul>  
                        </span>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <img src={Tower} alt='icon' className="net_img"  />
                    </Col> 
                </Row>
            </div>
            <FooterComponent normal={true}  />
        </>
    )
}

export default MobielNetPage