import React, {useState} from "react";
import { Row, Col } from 'antd'
import HeaderComponent from '../../components/header'
import { Ict1, Ict2, Ict3} from '../../assets/images/ict'
import {Enr1, Enr2} from '../../assets/images/energy'
import {ArrowRight} from '../../assets/images'
import FooterComponent from "../../components/footer";
import './style.css'
const EnergyPage = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const toggleMenu = () => {
        console.log("menuVisible", menuVisible);
        setMenuVisible(!menuVisible);
    };
    return(
        <>
            <HeaderComponent flag={false} toggleMenu={toggleMenu} menuVisible={menuVisible}  /> 
            <div className="main_head_enr">
                <Row>
                    <Col span={4} />
                    <Col span={11}>
                        <h1 className="fix_head_txt"> Energy <span className="head_cay_txt"> Solutions </span> </h1>
                        <span className="head_sub_txt"> 
                        Tap into the power of the environment to meet your energy requirements. Explore sustainable solutions that harness natural resources for a greener future.
                        </span>
                    </Col>
                </Row>
                <Row className="brd_row">
                    <Col span={4} />
                    <Col span={11}>
                        <span className="head_brd_txt"> 
                           Home / Energy Solutions
                        </span>
                    </Col>
                </Row>
            </div>
            <div>
                <Row className="center_div" gutter={[10,10]}>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Solar Solutions </h1>
                        <span> 
                            <p>
                                <img src={ArrowRight}  alt='icon' className="arrow_icon" />
                                Design and size system based on energy needs.
                            </p>
                            <p>
                                <img src={ArrowRight}  alt='icon' className="arrow_icon" />
                                Installation of solar equipment (solar panels, inverters, racking)
                            </p>
                            <ul>
                                <li> Install the racking or mounts for the panels. </li>
                                <li> Connect the solar panels to racking equipment. </li> 
                                <li> Install a solar inverter. </li> 
                            </ul>
                        </span>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <img src={Enr1} alt='icon' className="net_img" />
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <img src={Enr2} alt='icon' className="net_img"  />
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> LED (Light Emitting Diode) Solutions </h1>
                        <span> 
                            <p>
                                <img src={ArrowRight}  alt='icon' className="arrow_icon" />
                                LED Installations
                            </p>
                            <p>
                                <img src={ArrowRight}  alt='icon' className="arrow_icon" />
                                Street Light solutions
                            </p>
                            <ul>
                                <li> Parks Lighting Solutions </li>
                                <li> Malls and Buildings Solutions </li>
                                <li> Installation of LED & Solar Solutions </li> 
                            </ul>
                        </span>
                    </Col>
                </Row>
            </div>
            <FooterComponent normal={true}  />
        </>
    )
}

export default EnergyPage