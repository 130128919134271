import React from "react";
import "./style.css";
import { Button, Modal, Row, Col } from "antd";
import MenuPagelogo from "../../assets/images/Menupage.svg";
import FooterBg from '../../assets/images/footer_bg.png'
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const MenuPage = ({ onClose }) => {
  return (
    <>
      <Modal
        style={{
          top: 2,
        }}
        visible={true}
        footer={null}
        onCancel={onClose}
        className="menu-page"
        // width={1800}
        width="105vw"
        title={
          <Row align="middle" className="mod_hed">
            <Col span={12}>
              <img
                src={MenuPagelogo}
                alt="Menu Page Logo"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </Col>
            <Col span={12} className="eng">
              <span>English</span>
            </Col>
          </Row>
        }
      >
        <Row className="plr_8">
          <Col span={24}>
            <Row>
              <Col span={4}>
                <ul className="ul_style">
                  <li className="ul_style_li">
                    <a className="anc_tag" href="#">
                      Home
                    </a>
                  </li>
                  <li className="ul_style_li">
                    <Link className="anc_tag" to="/">
                      Products
                    </Link>
                  </li>
                  <li className="ul_style_li">
                    <Link className="anc_tag" to="/our_proj">
                      Projects
                    </Link>
                  </li>
                  <li className="ul_style_li">
                    <Link to="/licenses" className="anc_tag" > Licenses </Link>
                  </li>
                  <li className="ul_style_li">
                    <Link className="anc_tag" to="/gallery"> Gallery </Link>
                  </li>
                  <li className="ul_style_li">
                    <Link to="/contact_us" className="anc_tag"> Contact Us </Link>
                  </li>
                </ul>
              </Col>
              <Col span={4}>
                <ul className="ul_style">
                  <li className="ul_style_li_2">
                    <a className="our_ser" href="#">
                      Our Services
                    </a>
                  </li>
                  <li className="ul_style_li_2">
                    <Link className="our_ser_sub" to="/fixed_net"> Fixed Networks </Link>
                  </li>
                  <li className="ul_style_li_2">
                    <Link className="our_ser_sub" to="/mobile_net"> Mobile Networks </Link>
                  </li>
                  <li className="ul_style_li_2">
                    <Link className="our_ser_sub" to="/ict"> ICT Solutions </Link>
                  </li>
                  <li className="ul_style_li_2">
                    <Link className="our_ser_sub" to="/security"> Security Solutions </Link>
                  </li>
                  <li className="ul_style_li_2">
                    <Link className="our_ser_sub" to="/energy"> Energy Solutions </Link>
                  </li>
                  <li className="ul_style_li_2">
                    <Link className="our_ser_sub" to="/manage"> Managed Services </Link>
                  </li>
                  <li className="ul_style_li_2">
                    <Link className="our_ser_sub" to="/hr_out"> HR Outsourcing </Link>
                  </li>
                  <li className="ul_style_li_2">
                    <a className="our_ser_sub" href="#">
                      Auto Services
                    </a>
                  </li>
                  <li className="ul_style_li_2">
                    <a className="our_ser_sub" href="#">
                      Rent A Car Services
                    </a>
                  </li>
                </ul>
              </Col>
              <Col span={5}>
                <ul className="ul_style">
                  <li className="ul_style_li_2">
                    <a className="our_ser" href="#">
                      Group of Companies
                    </a>
                  </li>
                  <li className="ul_style_li_2">
                    <Link to="/" className="our_ser_sub"> Xad Technologies LLC UAE  </Link>
                  </li>
                  <li className="ul_style_li_2">
                    <a className="our_ser_sub" href="#">
                    Xad Technologies PVT LTD Pakistan
                    </a>
                  </li>
                  <li className="ul_style_li_2">
                    <Link className="our_ser_sub" to="/uk">  Xad Technologies LTD UK </Link>
                  </li>
                  <li className="ul_style_li_2">
                    <Link to="/ksa" className="our_ser_sub"> Xad Technologies LLC KSA </Link>
                  </li>
                  <li className="ul_style_li_2">
                    <Link className="our_ser_sub"  to="/torus"> Torus Investment LLC</Link>
                  </li>
                  <li className="ul_style_li_2">
                    <Link to="/watan" className="our_ser_sub"> Watan Properties LLC </Link>
                  </li>
                  <li className="ul_style_li_2">
                    <Link to="/innomix" className="our_ser_sub"> Innomix Manufacturing LLC </Link>
                  </li>
                  <li className="ul_style_li_2">
                    <Link to="/dc" className="our_ser_sub"> DC Pro Engineering LLC</Link>
                  </li>
                  <li className="ul_style_li_2">
                    <a className="our_ser_sub" href="#">
                    Xad Auto Services LLC
                    </a>
                  </li>
                </ul>
              </Col>
              <Col span={6}>
                <ul className="ul_style">
                  <li className="ul_style_li_2">
                    <a className="our_ser" href="#">
                      About Us
                    </a>
                  </li>
                  <li className="ul_style_li_2">
                    <Link className="our_ser_sub" to="/we_are"> Who we Are</Link>
                  </li>
                  <li className="ul_style_li_2">
                    <Link className="our_ser_sub" to="/organ_struct"> Company Structure</Link>
                  </li>
                  <li className="ul_style_li_2">
                    <Link className="our_ser_sub" to="/clients"> Major Clients </Link>
                  </li>
                  <li className="ul_style_li_2">
                    <Link to="/management" className="our_ser_sub"> Message From Management </Link>
                  </li>
                </ul>
              </Col>
              <Col span={5}>
                <ul className="ul_style">
                  <li className="ul_style_li_2">
                    <a className="our_ser" href="#">
                    Our Business
                    </a>
                  </li>
                  <li className="ul_style_li_2">
                    <Link to="/fixed_net" className="our_ser_sub"> Fixed Networks </Link>
                  </li>
                  <li className="ul_style_li_2">
                    <Link to="/mobile_net" className="our_ser_sub"> Mobile Networks </Link>
                  </li>
                  <li className="ul_style_li_2">
                    <Link to="/hr_out" className="our_ser_sub"> HR Outsourcing </Link>
                  </li>
                  <li className="ul_style_li_2">
                    <Link to="/manage" className="our_ser_sub"> Managed Services </Link>
                  </li>
                  <li className="ul_style_li_2">
                    <a className="our_ser_sub" href="#">
                    Car Rental Services
                    </a>
                  </li>
                  <li className="ul_style_li_2">
                    <a className="our_ser_sub" href="#">
                    Auto Mobile Workshop
                    </a>
                  </li>
                  <img src={FooterBg} alt="logo"  className="footer_bg_n" />
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default MenuPage;
