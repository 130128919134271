import React, {useState} from "react";
import { Row, Col} from 'antd'
import HeaderComponent from '../../components/header'
import FooterComponent from "../../components/footer";
import {EtisalatIcon, GreyTickIcon, WhiteArrowIcon} from '../../assets/images/our_projects'
import './style.css'
const EtisalatPage = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const toggleMenu = () => {
        console.log("menuVisible", menuVisible);
        setMenuVisible(!menuVisible);
    };
    return(
        <>
            <HeaderComponent flag={false} toggleMenu={toggleMenu} menuVisible={menuVisible}  /> 
            <div className="main_head_est">
                <Row>
                    <Col span={4} />
                    <Col span={11}>
                        <h1 className="fix_head_txt"> Project <p className="head_cay_txt"> Etisalat </p> </h1>
                        <span className="head_sub_txt"> 
                        Etisalat, a UAE-based multinational telecom, spans 15 countries in Asia, the Middle East, and Africa. By February 2014, it ranked as the 12th largest global mobile network with over 150 million customers.
                        </span>
                    </Col>
                </Row>
                <Row className="brd_row">
                    <Col span={4} />
                    <Col span={11}>
                        <span className="head_brd_txt"> 
                           Home /      Project Etisalat
                        </span>
                    </Col>
                </Row>
            </div>
            <div className="main_crr_div">
                <Row>
                    <Col span={4} className="icon_div"> <img src={EtisalatIcon} alt="icon" /> </Col>
                    <Col span={20}>
                        <h1 className="net_main_txt"> XAD Projects with Etisalat </h1>
                        <p className="net_sub_txt"> Here are some of our top projects with Etisalat </p>
                    </Col>
                </Row>
            </div>
            <div className="cent_div">
                <Row gutter={[10,10]} className="gry_div">
                    <Col span={2}>
                        <img src={GreyTickIcon} alt="tcic" className="icons" />
                    </Col>
                    <Col span={20}>
                        <p className="grry_txt"> Fixed Networks - Current </p>
                    </Col>
                    <Col span={2}>
                        <img src={WhiteArrowIcon} alt="tcic" className="icons"  />
                    </Col>
                </Row>
                <Row gutter={[10,10]} className="gry_div">
                    <Col span={2}>
                        <img src={GreyTickIcon} alt="tcic" className="icons" />
                    </Col>
                    <Col span={20}>
                        <p className="grry_txt"> Telecom - Fixed Networks </p>
                    </Col>
                    <Col span={2}>
                        <img src={WhiteArrowIcon} alt="tcic" className="icons"  />
                    </Col>
                </Row>
                <Row gutter={[10,10]} className="gry_div">
                    <Col span={2}>
                        <img src={GreyTickIcon} alt="tcic" className="icons" />
                    </Col>
                    <Col span={20}>
                        <p className="grry_txt"> Fixed Network / Managed Services - Current </p>
                    </Col>
                    <Col span={2}>
                        <img src={WhiteArrowIcon} alt="tcic" className="icons"  />
                    </Col>
                </Row>
                <Row gutter={[10,10]} className="gry_div">
                    <Col span={2}>
                        <img src={GreyTickIcon} alt="tcic" className="icons" />
                    </Col>
                    <Col span={20}>
                        <p className="grry_txt"> Fixed Network and Managed Services </p>
                    </Col>
                    <Col span={2}>
                        <img src={WhiteArrowIcon} alt="tcic" className="icons"  />
                    </Col>
                </Row>
                <Row gutter={[10,10]} className="gry_div">
                    <Col span={2}>
                        <img src={GreyTickIcon} alt="tcic" className="icons" />
                    </Col>
                    <Col span={20}>
                        <p className="grry_txt"> Telecom - Fixed Networks (2009 - 2011) </p>
                    </Col>
                    <Col span={2}>
                        <img src={WhiteArrowIcon} alt="tcic" className="icons"  />
                    </Col>
                </Row>
                <Row gutter={[10,10]} className="gry_div">
                    <Col span={2}>
                        <img src={GreyTickIcon} alt="tcic" className="icons" />
                    </Col>
                    <Col span={20}>
                        <p className="grry_txt"> Telecom - Fixed Networks (2010-2015) </p>
                    </Col>
                    <Col span={2}>
                        <img src={WhiteArrowIcon} alt="tcic" className="icons"  />
                    </Col>
                </Row>
                <Row gutter={[10,10]} className="gry_div">
                    <Col span={2}>
                        <img src={GreyTickIcon} alt="tcic" className="icons" />
                    </Col>
                    <Col span={20}>
                        <p className="grry_txt"> Managed Services - Marketing and Sales </p>
                    </Col>
                    <Col span={2}>
                        <img src={WhiteArrowIcon} alt="tcic" className="icons"  />
                    </Col>
                </Row>
            </div>
            <FooterComponent normal={true}  />
        </>
    )
}

export default EtisalatPage