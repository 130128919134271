import React, {useState} from "react";
import { Row, Col } from 'antd'
import HeaderComponent from '../../components/header'
import { Ict1, Ict2, Ict3} from '../../assets/images/ict'
import {ArrowRight} from '../../assets/images'
import FooterComponent from "../../components/footer";
import './style.css'
const IctPage = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const toggleMenu = () => {
        console.log("menuVisible", menuVisible);
        setMenuVisible(!menuVisible);
    };
    return(
        <>
            <HeaderComponent flag={false} toggleMenu={toggleMenu} menuVisible={menuVisible}  /> 
            <div className="main_head_ict">
                <Row>
                    <Col span={4} />
                    <Col span={11}>
                        <h1 className="fix_head_txt"> ICT <br/> <span className="head_cay_txt"> Solutions </span> </h1>
                        <span className="head_sub_txt"> 
                        Discover transformative solutions with cutting-edge technology for seamless digital evolution. Elevate your journey with innovation and strategic implementation.
                        </span>
                    </Col>
                </Row>
                <Row className="brd_row">
                    <Col span={4} />
                    <Col span={11}>
                        <span className="head_brd_txt"> 
                           Home / ICT Solutions
                        </span>
                    </Col>
                </Row>
            </div>
            <div>
                <Row className="center_div" gutter={[10,10]}>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Audiovisual Solutions (AV) </h1>
                        <span> 
                            <p>
                                <img src={ArrowRight}  alt='icon' className="arrow_icon" />
                                Audio & Video Conferencing systems installations
                            </p>
                            <p>
                                <img src={ArrowRight}  alt='icon' className="arrow_icon" />
                                Meeting Room / Class Rooms Solutions
                            </p>
                            <ul>
                                <li> Collaborative systems Installations and management </li>
                                <li> Control System Installation and management to enable user to communicate with technologies. </li> 
                            </ul>
                        </span>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <img src={Ict1} alt='icon' className="net_img" />
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <img src={Ict2} alt='icon' className="net_img"  />
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> ELV Solutions</h1>
                        <span> 
                            <p>
                                <img src={ArrowRight}  alt='icon' className="arrow_icon" />
                                Time Attendance System
                            </p>
                            <p>
                                <img src={ArrowRight}  alt='icon' className="arrow_icon" />
                                Telephone & PABX System
                            </p>
                            <ul>
                                <li> Access Control System </li>
                                <li> Fire Alarm devices and system </li>
                                <li> Lift Access Control Systems </li> 
                                <li> Vehicle Access Control </li> 
                                <li> BMS Solutions </li> 
                                <li> Structured Cabling & Computer Networks </li> 
                            </ul>
                        </span>
                    </Col>

                    <Col span={12} className="net_txt_col">
                        <h1 className="net_main_txt"> Data Centers & Storage Solution </h1>
                        <span> 
                            <p>
                                <img src={ArrowRight}  alt='icon' className="arrow_icon" />
                                Installation and Maintenance:
                            </p>
                            <ol>
                                <li> Setting up Data Center raised Floors </li>
                                <li> Data Center Equipment and Devices Instalaltions </li> 
                                <li> Air Conditioning System installation </li> 
                                <li> Related Infrastructure Installations </li> 
                                <li> SAN, NAS and Enterprise Storages </li> 
                            </ol>
                        </span>
                    </Col>
                    <Col span={12} className="net_txt_col">
                        <img src={Ict3} alt='icon' className="net_img" />
                    </Col>
                </Row>
            </div>
            <FooterComponent normal={true}  />
        </>
    )
}

export default IctPage