// NokiaIcon
import React, { useState } from "react";
import { Row, Col } from "antd";
import HeaderComponent from "../../components/header";
import FooterComponent from "../../components/footer";
import {Manage1, Manage2} from '../../assets/images/manage'
import {MessageAni} from '../../assets/images/animations'
import LottieAnimation from "../../components/animation";
import "./style.css";
const ManagementPage = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const toggleMenu = () => {
    console.log("menuVisible", menuVisible);
    setMenuVisible(!menuVisible);
  };
  return (
    <>
      <HeaderComponent
        flag={false}
        toggleMenu={toggleMenu}
        menuVisible={menuVisible}
      />
      <div className="main_head_management">
        <Row>
          <Col span={4} />
          <Col span={11}>
            <h1 className="fix_head_txt">
            Message from <p className="head_cay_txt"> Management </p>
            </h1>
            <span className="head_sub_txt">
            Thoughtful perspectives and directives from our esteemed leadership, conveying strategic vision and fostering a collaborative environment to drive organizational growth.
            </span>
          </Col>
        </Row>
        <Row className="brd_row">
          <Col span={4} />
          <Col span={11}>
            <span className="head_brd_txt">Home / Message from Management </span>
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          <Col span={24} align="middle" className="text-center">
            <LottieAnimation animationData={MessageAni} className="width_8" />
            <h1 className="top_clnt_txt"> Growing Strong & Reaching Higher </h1>
            <span className="top_sub_txt">
                Insightful guidance and wisdom shared by our esteemed senior leadership.
            </span>
          </Col>
        </Row>
        <Row className="msg_cntr_div">
            <Col span={16}>
                <img src={Manage1} alt="msss "/>
            </Col>
            <Col span={8}>
                <img src={Manage2} alt="mss 2" />
            </Col>
        </Row>
        <Row className="chairman_div">
            <Col span={24}>
                <h1 className="net_main_txt"> Our Chairman </h1>
                <p className="gry_txt"> 
                    Having solid roots and aiming for the top, after more than a decade of dynamic presence, we at XADTech are continually striving to provide exceptional solutions and services to all our clients across the Middle East and South Asia as a well renowned company operating in the new era of telecommunication.
                    Over the past decade, we have greatly expanded our product and services portfolio to provide an expansive range of solutions in the telecom field, and have been consistently awarded projects with regional telecom providers and multinational corporations with our international grade certifications. Based on the efforts of our team, we are proud to have served more than Two million customers and taken on a year by year growth path as one of the preferred partners for regional telecom operators.
                    As a company, XAD will continue to strive for higher goals and provide good career path and benefits to our employees, partners and clients alike. We look forward to a new era of growth as we continue to grow and develop both locally and internationally
                </p>
                <p className="chair_txt_black">         
                    Dr. Khalid Omar Al Midfa 
                </p>
                <p className="chair_txt_gry">  Chairman </p>
            </Col>
        </Row>
      </div>
      <FooterComponent normal={true} />
    </>
  );
};

export default ManagementPage;